.holiday-gift-guide-landing-2023 {
    .title-section {
        background-color: #8c4647;
        > div.row > div.col-12 {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 300px;
            > div.ms-text-block {
                padding: 0;

                .title-top {
                    font-family: 'Futura PT Bold', Arial, Tahoma, sans-serif;
                    font-size: 36px;
                    text-align: center;
                    color: white;
                }
                .title-bottom {
                    font-family: 'IntegralCF-Bold' !important;
                    font-size: 48px;
                    text-align: center;
                    color: white;
                }
            }
        }
    }
    .gifts-for-boat-section {
        padding-top: 30px;
    }

    .gifts-for-him-section,
    .gifts-with-lift-section {
        div.row {
            flex-direction: row;
            @media (max-width: 768px) {
                flex-direction: column !important;
            }
        }
    }

    .gifts-for-boat-section,
    .gifts-for-her-section {
        div.row {
            flex-direction: row-reverse;
            @media (max-width: 768px) {
                flex-direction: column !important;
            }
        }
    }

    .gifts-for-boat-section,
    .gifts-for-him-section,
    .gifts-for-her-section,
    .gifts-with-lift-section {
        > div.row {
            display: flex;
            align-items: center;

            > div {
                width: 50%;
                max-width: 50%;
                @media (max-width: 768px) {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }

        .holiday-image {
            .ms-content-block__details {
                display: none;
            }
            .ms-content-block__image {
                justify-content: center;
                display: flex;
                img {
                    max-width: 300px;
                    margin: 0;
                    padding: 20px 0;
                }
            }
        }
        .holiday-text {
            .holiday-title {
                text-align: center;
                a {
                    font-family: 'IntegralCF-Bold' !important;
                    font-size: 36px;
                    color: #8c4647;
                    padding-bottom: 25px;
                    text-decoration: none;
                }
            }
            .holiday-desc {
                text-align: center;
                a {
                    font-family: 'Futura PT Bold', Arial, Tahoma, sans-serif;
                    font-size: 18px;
                    line-height: 20px;
                    text-decoration: none;
                    @media (max-width: 768px) {
                        padding-bottom: 100px;
                    }
                }
            }
            .holiday-link {
                text-align: center;
                a {
                    font-family: 'Futura PT Bold', Arial, Tahoma, sans-serif;
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 20px;
                    transition: 0.5s;
                    color: #8c4647;
                    text-decoration: none;
                    @media (max-width: 768px) {
                        padding-bottom: 100px;
                    }
                }
                a:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}

.homepage-holiday-gift-banner {
    background-color: #8c4647;
    margin-bottom: 50px;
    > div.row > div.col-12 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px;
        > div.ms-text-block {
            padding: 0;

            .title-top {
                font-family: 'Futura PT Bold', Arial, Tahoma, sans-serif;
                font-size: 36px;
                text-align: center;
                color: white;
            }
            .title-bottom {
                font-family: 'IntegralCF-Bold' !important;
                font-size: 48px;
                text-align: center;
                color: white;
            }
            p {
                text-align: center;
                a:hover {
                    transform: scale(1.1);
                }
                a {
                    padding-top: 30px;
                    font-family: 'Futura PT Bold', Arial, Tahoma, sans-serif;
                    font-size: 28px;
                    color: white;
                    text-decoration: none;
                    transition: 0.5s;
                }
            }
        }
    }
}
