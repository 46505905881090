.hide-filtering {
    .ms-search-result-container__product-section {
        width: 100%;
    }

    .ms-search-result-container__refiner-section {
        display: none;
    }
}

//Hide Breadcrumbs
.ms-search-result-container__category-nav-section {
    padding-top: 10px;
    nav.ms-search-result-container__category-hierarchy {
        color: transparent;
        pointer-events: none;
        cursor: default;
        a {
            color: transparent;
            pointer-events: none;
            cursor: default;
        }
    }
}

.ms-product-search-result__item {
    h4.msc-product__title {
        font-family: 'IntegralCF-Bold', Arial, Tahoma, sans-serif !important;
    }
}

// Collection Header Title
.ob-title {
    display: none;
}

//Filters
.ms-search-result-container__refiner-section {
    .ms-refine-submenu.list-group.AssociatedVariantSizes,
    .ms-refine-submenu.list-group.IsSearchable,
    .ms-refine-submenu.list-group.ReviewRating,
    .ms-refine-submenu.list-group.AssociatedVariantColours,
    .ms-refine-submenu.list-group.Rating {
        display: none;
    }
}

.ms-search-result-container__Sort-by-category {
    bottom: 15px;
}

//Collection Images
/* .msc-product__image {
  width: 300px;
} */

@media (max-width: 992px) {
    .ms-search-result-container .ms-product-search-result__item {
        display: flex;
        justify-content: center;
    }
}

ul.Price {
    li {
        border: none;
        background-color: inherit;
    }
}

@media (max-width: 992px) {
    .ms-search-result-container .ms-product-search-result__item {
        flex-direction: column;
    }
}

//Wakeboard Cat Page Filter Ordering

.park-wakeboard-cat-container {
    .ms-search-result-container__refine-product-section {
        .ms-search-result-container__refiner-section {
            .ms-search-result-container__refine-menu {
                display: flex;
                flex-direction: column;
                .Category {
                    order: 0;
                }
                .Construction {
                    order: 1;
                    div {
                        ul.Construction {
                            display: flex;
                            flex-direction: column;
                            #Construction_0 {
                                order: 0;
                            }
                            #Construction_1 {
                                order: 1;
                            }
                        }
                    }
                }
                .Rocker {
                    order: 2;
                    div {
                        ul.Rocker {
                            display: flex;
                            flex-direction: column;
                            #Rocker_1 {
                                order: 0;
                            }
                            #Rocker_0 {
                                order: 1;
                            }
                        }
                    }
                }
                .Features {
                    order: 3;
                    div {
                        ul.Features {
                            display: flex;
                            flex-direction: column;
                            #Features_0 {
                                order: 0;
                            }
                            #Features_2 {
                                order: 1;
                            }
                            #Features_1 {
                                order: 2;
                            }
                        }
                    }
                }
                .Size {
                    order: 4;
                    div {
                        ul.Size {
                            display: flex;
                            flex-direction: column;
                            #Size_16 {
                                order: 0;
                            }
                            #Size_6 {
                                order: 1;
                            }
                            #Size_7 {
                                order: 2;
                            }
                            #Size_8 {
                                order: 3;
                            }
                            #Size_9 {
                                order: 4;
                            }
                            #Size_10 {
                                order: 5;
                            }
                            #Size_17 {
                                order: 6;
                            }
                            #Size_18 {
                                order: 7;
                            }
                            #Size_11 {
                                order: 8;
                            }
                            #Size_0 {
                                order: 9;
                            }
                            #Size_12 {
                                order: 10;
                            }
                            #Size_1 {
                                order: 11;
                            }
                            #Size_19 {
                                order: 12;
                            }
                            #Size_2 {
                                order: 13;
                            }
                            #Size_3 {
                                order: 14;
                            }
                            #Size_20 {
                                order: 15;
                            }
                            #Size_4 {
                                order: 16;
                            }
                            #Size_13 {
                                order: 17;
                            }
                            #Size_14 {
                                order: 18;
                            }
                            #Size_15 {
                                order: 19;
                            }
                            #Size_5 {
                                order: 20;
                            }
                            #Size_21 {
                                order: 21;
                            }
                            #Size_22 {
                                order: 22;
                            }
                        }
                    }
                }

                .Price {
                    order: 5;
                }
            }
        }
    }
}

.boat-wakeboard-cat-container {
    .ms-search-result-container__refine-product-section {
        .ms-search-result-container__refiner-section {
            .ms-search-result-container__refine-menu {
                display: flex;
                flex-direction: column;
                .Category {
                    order: 0;
                }
                .Construction {
                    order: 1;
                    div {
                        ul.Construction {
                            display: flex;
                            flex-direction: column;
                            #Construction_1 {
                                order: 0;
                            }
                            #Construction_2 {
                                order: 1;
                            }
                            #Construction_0 {
                                order: 2;
                            }
                        }
                    }
                }
                .Rocker {
                    order: 2;
                    div {
                        ul.Rocker {
                            display: flex;
                            flex-direction: column;
                            #Rocker_0 {
                                order: 0;
                            }
                            #Rocker_1 {
                                order: 1;
                            }
                            #Rocker_2 {
                                order: 3;
                            }
                        }
                    }
                }
                .Features {
                    order: 3;
                    div {
                        ul.Features {
                            display: flex;
                            flex-direction: column;
                            #Features_1 {
                                order: 0;
                            }
                            #Features_0 {
                                order: 1;
                            }
                            #Features_2 {
                                order: 2;
                            }
                        }
                    }
                }
                .Size {
                    order: 4;
                    div {
                        ul.Size {
                            display: flex;
                            flex-direction: column;
                            #Size_11 {
                                order: 0;
                            }
                            #Size_12 {
                                order: 1;
                            }
                            #Size_13 {
                                order: 2;
                            }
                            #Size_4 {
                                order: 3;
                            }
                            #Size_0 {
                                order: 4;
                            }
                            #Size_5 {
                                order: 5;
                            }
                            #Size_7 {
                                order: 6;
                            }
                            #Size_1 {
                                order: 7;
                            }
                            #Size_2 {
                                order: 8;
                            }
                            #Size_8 {
                                order: 9;
                            }
                            #Size_6 {
                                order: 10;
                            }
                            #Size_9 {
                                order: 11;
                            }
                            #Size_3 {
                                order: 12;
                            }
                            #Size_14 {
                                order: 13;
                            }
                            #Size_10 {
                                order: 14;
                            }
                            #Size_15 {
                                order: 15;
                            }
                        }
                    }
                }

                .Price {
                    order: 5;
                }
            }
        }
    }
}

//Wakesurf Cat Page Filter Ordering

.wakesurf-cat-container {
    .ms-search-result-container__refine-product-section {
        .ms-search-result-container__refiner-section {
            .ms-search-result-container__refine-menu {
                display: flex;
                flex-direction: column;
                .Category {
                    order: 0;
                }
                .Construction {
                    order: 1;
                    div {
                        ul.Construction {
                            display: flex;
                            flex-direction: column;
                            #Construction_1 {
                                order: 0;
                            }
                            #Construction_0 {
                                order: 1;
                            }
                            #Construction_2 {
                                order: 2;
                            }
                        }
                    }
                }
                .Style {
                    order: 2;
                    div {
                        ul.Style {
                            display: flex;
                            flex-direction: column;
                            #Style_1 {
                                order: 0;
                            }
                            #Style_0 {
                                order: 1;
                            }
                            #Style_2 {
                                order: 2;
                            }
                            #Style_3 {
                                order: 3;
                            }
                        }
                    }
                }
                .Size {
                    order: 3;
                    div {
                        ul.Size {
                            display: flex;
                            flex-direction: column;
                            #Size_3 {
                                order: 1;
                            }
                            #Size_1 {
                                order: 2;
                            }
                            #Size_17 {
                                order: 3;
                            }
                            #Size_8 {
                                order: 4;
                            }
                            #Size_9 {
                                order: 5;
                            }
                            #Size_2 {
                                order: 6;
                            }
                            #Size_18 {
                                order: 7;
                            }
                            #Size_10 {
                                order: 8;
                            }
                            #Size_19 {
                                order: 9;
                            }
                            #Size_0 {
                                order: 10;
                            }
                            #Size_16 {
                                order: 11;
                            }
                            #Size_4 {
                                order: 12;
                            }
                            #Size_5 {
                                order: 13;
                            }
                            #Size_20 {
                                order: 14;
                            }
                            #Size_13 {
                                order: 15;
                            }
                            #Size_21 {
                                order: 16;
                            }
                            #Size_11 {
                                order: 17;
                            }
                            #Size_12 {
                                order: 18;
                            }
                            #Size_22 {
                                order: 19;
                            }
                            #Size_6 {
                                order: 20;
                            }
                            #Size_14 {
                                order: 21;
                            }
                            #Size_7 {
                                order: 22;
                            }
                            #Size_23 {
                                order: 23;
                            }
                            #Size_24 {
                                order: 24;
                            }
                            #Size_15 {
                                order: 25;
                            }
                        }
                    }
                }
                .Price {
                    order: 4;
                }
            }
        }
    }
}

//Binding Cat Filter Ordering

.binding-cat-container {
    .ms-search-result-container__refine-product-section {
        .ms-search-result-container__refiner-section {
            .ms-search-result-container__refine-menu {
                display: flex;
                flex-direction: column;
                .Category {
                    order: 0;
                }
                .Style {
                    order: 1;
                    div {
                        ul.Style {
                            display: flex;
                            flex-direction: column;
                            #Style_0 {
                                order: 0;
                            }
                            #Style_3 {
                                order: 1;
                            }
                            #Style_4 {
                                order: 2;
                            }
                            #Style_1 {
                                order: 3;
                            }
                            #Style_2 {
                                order: 4;
                            }
                        }
                    }
                }
                .Flex {
                    order: 2;
                    div {
                        ul.Flex {
                            display: flex;
                            flex-direction: column;
                            #Flex_1 {
                                order: 0;
                            }
                            #Flex_0 {
                                order: 1;
                            }
                            #Flex_2 {
                                order: 2;
                            }
                        }
                    }
                }
                .Features {
                    order: 3;
                    div {
                        ul.Features {
                            display: flex;
                            flex-direction: column;
                            #Features_4 {
                                order: 0;
                            }
                            #Features_3 {
                                order: 1;
                            }
                            #Features_0 {
                                order: 2;
                            }
                            #Features_1 {
                                order: 3;
                            }
                            #Features_2 {
                                order: 4;
                            }
                        }
                    }
                }
                .Size {
                    order: 4;
                    div {
                        ul.Size {
                            display: flex;
                            flex-direction: column;
                            /*
                            #Size_4 {
                                order: 0;
                            }
                            #Size_19 {
                                order: 1;
                            }
                            #Size_14 {
                                order: 2;
                            }
                            #Size_5 {
                                order: 3;
                            }
                            #Size_2 {
                                order: 4;
                            }
                            #Size_11 {
                                order: 5;
                            }
                            #Size_20 {
                                order: 6;
                            }
                            #Size_3 {
                                order: 7;
                            }
                            #Size_15 {
                                order: 8;
                            }
                            #Size_0 {
                                order: 9;
                            }
                            #Size_8 {
                                order: 10;
                            }
                            #Size_1 {
                                order: 11;
                            }
                            #Size_7 {
                                order: 12;
                            }
                            #Size_6 {
                                order: 13;
                            }
                            #Size_18 {
                                order: 14;
                            }
                            #Size_9 {
                                order: 15;
                            }
                            #Size_10 {
                                order: 16;
                            }
                            #Size_21 {
                                order: 17;
                            }
                            #Size_12 {
                                order: 18;
                            }
                            #Size_22 {
                                order: 19;
                            }
                            #Size_16 {
                                order: 20;
                            }
                            #Size_23 {
                                order: 21;
                            }
                            #Size_17 {
                                order: 22;
                            }
                            #Size_13 {
                                order: 23;
                            }
                            */
                        }
                    }
                }
                .Price {
                    order: 5;
                }
            }
        }
    }
}

//Wakesurf Cat Filter Ordering

.wakesurf-cat-container {
    .ms-search-result-container__refine-product-section {
        .ms-search-result-container__refiner-section {
            .ms-search-result-container__refine-menu {
                display: flex;
                flex-direction: column;
                .Category {
                    order: 0;
                }
                .Construction {
                    order: 1;
                    div {
                        ul.Construction {
                            display: flex;
                            flex-direction: column;
                            #Construction_1 {
                                order: 0;
                            }
                            #Construction_0 {
                                order: 1;
                            }
                            #Construction_3 {
                                order: 2;
                            }
                            #Construction_2 {
                                order: 3;
                            }
                        }
                    }
                }
                .Style {
                    order: 2;
                    div {
                        ul.Style {
                            display: flex;
                            flex-direction: column;
                            #Style_1 {
                                order: 0;
                            }
                            #Style_0 {
                                order: 1;
                            }
                            #Style_3 {
                                order: 2;
                            }
                            #Style_2 {
                                order: 3;
                            }
                        }
                    }
                }
                .Size {
                    order: 3;
                    div {
                        ul.Size {
                            display: flex;
                            flex-direction: column;
                            #Size_1 {
                                order: 0;
                            }
                            #Size_2 {
                                order: 1;
                            }
                            #Size_14 {
                                order: 2;
                            }
                            #Size_7 {
                                order: 3;
                            }
                            #Size_8 {
                                order: 4;
                            }
                            #Size_3 {
                                order: 5;
                            }
                            #Size_15 {
                                order: 6;
                            }
                            #Size_16 {
                                order: 7;
                            }
                            #Size_17 {
                                order: 8;
                            }
                            #Size_0 {
                                order: 9;
                            }
                            #Size_13 {
                                order: 10;
                            }
                            #Size_9 {
                                order: 11;
                            }
                            #Size_5 {
                                order: 12;
                            }
                            #Size_18 {
                                order: 13;
                            }
                            #Size_19 {
                                order: 14;
                            }
                            #Size_4 {
                                order: 15;
                            }
                            #Size_10 {
                                order: 16;
                            }
                            #Size_11 {
                                order: 17;
                            }
                            #Size_20 {
                                order: 18;
                            }
                            #Size_21 {
                                order: 19;
                            }
                            #Size_6 {
                                order: 20;
                            }
                            #Size_22 {
                                order: 21;
                            }
                            #Size_23 {
                                order: 22;
                            }
                            #Size_12 {
                                order: 23;
                            }
                        }
                    }
                }
                .Price {
                    order: 4;
                }
            }
        }
    }
}

//Wake Skate Cat Filter Ordering

.wakeskate-cat-container {
    .ms-search-result-container__refine-product-section {
        .ms-search-result-container__refiner-section {
            .ms-search-result-container__refine-menu {
                display: flex;
                flex-direction: column;
                .Category {
                    order: 0;
                }
                .Construction {
                    order: 1;
                    div {
                        ul.Construction {
                            display: flex;
                            flex-direction: column;
                            #Construction_0 {
                                order: 0;
                            }
                            #Construction_1 {
                                order: 1;
                            }
                        }
                    }
                }
                .Features {
                    order: 2;
                    div {
                        ul.Features {
                            display: flex;
                            flex-direction: column;
                            #Features_0 {
                                order: 0;
                            }
                        }
                    }
                }
                .Size {
                    order: 3;
                    div {
                        ul.Size {
                            display: flex;
                            flex-direction: column;
                            #Size_0 {
                                order: 0;
                            }
                            #Size_1 {
                                order: 1;
                            }
                            #Size_2 {
                                order: 2;
                            }
                            #Size_3 {
                                order: 3;
                            }
                        }
                    }
                }
                .Price {
                    order: 4;
                }
            }
        }
    }
}

//Foil Cat Filter Ordering

.foilboard-cat-container {
    .ms-search-result-container__refine-product-section {
        .ms-search-result-container__refiner-section {
            .ms-search-result-container__refine-menu {
                display: flex;
                flex-direction: column;
                .Category {
                    order: 0;
                }
                .Construction {
                    order: 1;
                    div {
                        ul.Construction {
                            display: flex;
                            flex-direction: column;
                            #Construction_0 {
                                order: 0;
                            }
                            #Construction_1 {
                                order: 1;
                            }
                            #Construction_2 {
                                order: 2;
                            }
                        }
                    }
                }
                .Style {
                    order: 2;
                    div {
                        ul.Style {
                            display: flex;
                            flex-direction: column;
                            #Style_2 {
                                order: 0;
                            }
                            #Style_0 {
                                order: 1;
                            }
                            #Style_1 {
                                order: 2;
                            }
                        }
                    }
                }
                .Size {
                    order: 3;
                    div {
                        ul.Size {
                            display: flex;
                            flex-direction: column;
                            #Size_0 {
                                order: 0;
                            }
                            #Size_1 {
                                order: 1;
                            }
                            #Size_2 {
                                order: 2;
                            }
                            #Size_3 {
                                order: 3;
                            }
                        }
                    }
                }
                .Price {
                    order: 4;
                }
            }
        }
    }
}

//Vest Cat Filter Ordering

.cga-cat-container {
    .ms-search-result-container__refine-product-section {
        .ms-search-result-container__refiner-section {
            .ms-search-result-container__refine-menu {
                display: flex;
                flex-direction: column;
                .Category {
                    order: 0;
                }
                .Gender {
                    order: 1;
                    div {
                        ul.Gender {
                            display: flex;
                            flex-direction: column;
                            #Gender_0 {
                                order: 0;
                            }
                            #Gender_2 {
                                order: 1;
                            }
                            #Gender_5 {
                                order: 2;
                            }
                            #Gender_3 {
                                order: 3;
                            }
                            #Gender_1 {
                                order: 4;
                            }
                            #Gender_4 {
                                order: 5;
                            }
                        }
                    }
                }
                .Style {
                    order: 2;
                    div {
                        ul.Style {
                            display: flex;
                            flex-direction: column;
                            #Style_1 {
                                order: 0;
                            }
                            #Style_0 {
                                order: 1;
                            }
                        }
                    }
                }
                .Color {
                    order: 3;
                }
                .Price {
                    order: 4;
                }
            }
        }
    }
}

// Holiday Gift Guide Filtering 2023
.holiday-gift-guide-2023 {
    .ms-search-result-container__refine-product-section {
        .ms-search-result-container__refiner-section {
            .ms-search-result-container__refine-menu {
                display: flex;
                flex-direction: column;
                .Category {
                    display: block !important;
                    div {
                        ul.Category {
                            display: flex;
                            flex-direction: column;
                            #Category_0,
                            #Category_1,
                            #Category_2,
                            #Category_3,
                            #Category_4,
                            #Category_5,
                            #Category_6,
                            #Category_7,
                            #Category_8,
                            #Category_9,
                            #Category_10,
                            #Category_11 {
                                display: none;
                            }
                        }
                    }
                }
                .ms-refine-submenu {
                    display: none;
                }
            }
        }
    }
}

// Fins and Parts Filtering 2023
.finsnparts-cat-container {
    .ms-search-result-container__refine-product-section {
        .ms-search-result-container__refiner-section {
            .ms-search-result-container__refine-menu {
                display: flex;
                flex-direction: column;
                .Activity {
                    order: 0;
                }
                .Price {
                    order: 1;
                }
                .Category {
                    order: 2;
                    display: none;
                }
            }
        }
    }
}
