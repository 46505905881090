.employee-image > div > picture > img {
    width: 300px;
}
.ms-content-block.support-hr {
    min-height: 30px;
}
.employee-image > div.ms-content-block__details > div.ms-content-block__title {
    text-align: center;
}

.return-to-top-link {
    padding-top: 30px;
    padding-bottom: 10px;
    border-bottom: 1px black;
}

.flex-direction-col {
    flex-direction: column;
}

.obrien-vimeo {
    height: 600px;
    @media only screen and (max-width: 768px) {
        height: 50vw;
    }
}
