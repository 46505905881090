.graphic-contest {
    .board-display-container {
        > .row {
            display: flex;
            flex-direction: row;
            @media (max-width: 768px) {
                flex-direction: column;
            }
            > .col-12 {
                max-width: 20%;
                .board-display-section {
                    margin-bottom: -5px;
                    a {
                        border: none;
                    }
                    .ms-content-block__details {
                        display: none;
                    }
                }
                @media (max-width: 768px) {
                    max-width: 100%;
                }
            }
        }
    }
    .graphic-contest-embed {
        .strawpoll-embed {
            max-width: 1250px;
            height: 800px;
        }
    }
}
