$integral: 'IntegralCF-Bold';
$futura: 'Futura PT Bold';
$pitch: 'Pitch Bold';
$liquid-font-path: '../../../liquid/webfonts';
$bodyfonts: $futura, Arial, Tahoma, sans-serif;

@font-face {
    font-family: $integral;
    src: url('#{$liquid-font-path}/IntegralCF-Bold.otf') format('otf'), url('#{$liquid-font-path}/IntegralCF-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $futura;
    src: url('#{$liquid-font-path}/futura-pt-book.otf') format('otf'), url('#{$liquid-font-path}/futura-pt-book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $pitch;
    src: url('#{$liquid-font-path}/Pitch-Bold.otf') format('otf'), url('#{$liquid-font-path}/Pitch-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TriesterSans-Vector';
    src: url('#{$liquid-font-path}/TriesterVector.otf') format('otf'), url('#{$liquid-font-path}/TriesterVector.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'C23LfStyle';
    src: url('#{$liquid-font-path}/C23LfStyle-Regular.otf') format('otf'),
        url('#{$liquid-font-path}/C23LfStyle-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proto Mono Medium';
    src: url('#{$liquid-font-path}/Proto-Mono-Medium.otf') format('otf'), url('#{$liquid-font-path}/Proto-Mono-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proto Mono Semi Bold';
    src: url('#{$liquid-font-path}/Proto-Mono-Semi-Bold.otf') format('otf'),
        url('#{$liquid-font-path}/Proto-Mono-Semi-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html,
body {
    font-family: $bodyfonts;
    font-weight: 300;
}
h1,
h2,
h3,
h4,
h5 {
    font-family: IntegralCF-Bold, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    line-height: 1 !important;
    margin-bottom: 8px !important;
    font-weight: 400;
}

strong {
    font-weight: 700;
}
