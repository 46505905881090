.ms-content-block.team-stack {
    padding: 15px;
    padding-bottom: 50px;
}

.team-stack > div > h4 {
    padding-top: 15px;
    border-bottom: 1px $brandSecondaryColor;
    border-bottom-style: solid;
    padding-bottom: 5px;
    font-size: 1.3rem;
}

.team-stack > div > picture > img {
    @media screen and (max-width: $screen-sm) {
        width: 100%;
    }
}

.team-pro-nico > div > h4 {
    font-size: 1.2rem;
    padding-bottom: 6px;
}

.legends-title {
    margin-top: 0;
    padding-top: 25px;
}

.team-int-fynn > div > h4,
.team-int-busty > div > h4 {
    font-size: 1.1rem;
    padding-bottom: 8px;
}

h4.team-text-stack {
    font-size: 1.1rem;
    text-align: center;
}

ul.team-text-list {
    text-align: center;
}

.national-team-section,
.regional-team-section {
    padding-bottom: 70px;
}

.regional-team-section > div > div > div > div > h3 {
    font-size: 1.1rem;
}

.regional-team-section > div > div > div > div.border-left,
.regional-team-section > div > div > div > div.border-right {
    @media screen and (max-width: $screen-sm) {
        border-left: none !important;
        border-right: none !important;
    }
}

/* PRO TEAM TEMPLATE PAGE START */

.team-title-container {
    padding-bottom: 30px;
    .team-title-container-title {
    }
    .team-title-container-divider {
    }
}

.team-content-container {
    .team-content-container-image {
        display: flex;
        justify-content: center;
    }

    .team-content-container-divider {
    }

    .team-content-container-content-container {
        .team-content-container-content-container-left {
            padding: 0 10px;
            .team-content-container-content-container-left-text {
                strong {
                    font-size: 18px;
                }
            }
        }
        .team-content-container-content-container-right {
            padding: 0 10px;
            .team-content-container-content-container-right-image {
                display: flex;
                justify-content: center;
            }

            .team-content-container-content-container-right-divider {
            }
            .team-content-container-content-container-right-divider-carousel {
                .team-content-container-content-container-right-divider-carousel-item {
                    display: flex;
                    justify-content: center;
                }
                .msc-carousel__indicators {
                    display: none;
                }
            }
        }
    }

    .team-title-container-instagram {
        padding-top: 30px;
    }
}

/* PRO TEAM TEMPLATE PAGE END */

.jake-caster-image {
    display: none !important;
}

.team-stack {
    img {
        transition: filter 250ms;
        &:hover {
            filter: grayscale(75%);
        }
    }
}

/* Team Page Adventure IO Section */

.pro-team-adventure-io {
    .col-12 {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .pro-team-adventure-io-text {
    }
    .pro-team-adventure-io-banner {
        max-width: 300px;
    }
}

.athlete-xp-adventure-io {
    padding-bottom: 60px;
    .col-12 {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .athlete-xp-adventure-io-text {
    }
    .athlete-xp-adventure-io-banner {
        max-width: 300px;
    }
}
