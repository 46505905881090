.lf-addresses-container {
    .lf-addresses {
        .msc-address-list__heading {
            text-align: center;
            padding: 30px 0;
            font-size: 36px;
            text-transform: uppercase;
        }
        .msc-address-list__button-add {
            background-color: #dddd4a;
            border-color: #dddd4a;
        }

        .msc-address-list__button-add:hover,
        .msc-address-list__button-add:focus {
            background-color: #aaaa38;
            border-color: #aaaa38;
        }
    }
}
