.softgoods-container {
    height: 140px;
    max-height: 140px;
    background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MA6aqf?ver=d043);
    //ver 2
    // background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MA6aqc?ver=bb51);
    background-size: cover;
    @media (max-width: 768px) {
        height: 240px;
        max-height: 240px;
        background-size: cover;
        background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MA6aqi?ver=ec54);
        //ver 2
        // background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MA6d1j?ver=0a26);
    }
    .row {
        height: 100%;
        .col-12 {
            height: 100%;
        }
    }
    .softgoods-container-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;

        @media (max-width: 768px) {
        }

        .ms-content-block__image {
            display: none;
        }

        .ms-content-block__details {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;
            .ms-content-block__text {
                display: flex;
                justify-content: center;
                flex-direction: row;
                width: 100%;
                max-width: 100%;
                text-align: center;
                a {
                    text-decoration: none;
                    letter-spacing: 2px;
                    color: #fff !important;
                }

                a:hover {
                    color: #fff !important;
                }
            }
        }
    }
}
