.lf-wishlist {
    .ms-wishlist-items__heading {
        padding-bottom: 30px;
        padding-top: 30px;
        text-align: center;
        font-size: 36px;
        text-transform: uppercase;
    }
    .ms-wishlist-items__product-add-button {
        width: 100%;
        border-color: #dddd4a;
    }
    .ms-wishlist-items__product-add-button:hover {
        background-color: rgba(221, 221, 74, 0.3);
        border-color: #dddd4a;
    }
}
