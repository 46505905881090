.tech-sticky-header {
    padding: 25px 0;
    background: #f8f7f6;
    .header-button-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        > p {
            width: 20%;
            text-align: center;
            border-left: 1px solid black;
            border-right: 1px solid black;
            display: flex;
            align-items: center;
        }

        .header-buttons {
            width: 100%;
            display: flex;
            justify-content: center;
            justify-items: center;
            text-decoration: none;
        }
    }
    @media (max-width: 768px) {
        display: none;
    }
}

.tech-header-container {
}

.tech-top-container {
    > div.row > div.col-12:first-child {
        background-color: black;
    }
    .tech-top-sub-container {
        > div.row > div.col-md-3 > div > a,
        > div.row > div.col-md-3 > div {
            margin: 0;
            padding: 0;
            border: 0;
        }
        .tech-top-sub-container-flight {
        }
        .tech-top-sub-container-altitude {
        }
        .tech-top-sub-container-wheelbase {
        }
        .tech-top-sub-container-decks {
        }
    }
    .tech-top-container-title {
        margin-top: -5px;
    }
    .tech-top-container-table {
        padding: 50px 10px;
    }
}

.tech-main-container {
    .tech-sub-container {
        height: 1000px;
        > div {
            height: 1000px;
        }
        .tech-desc-text-container {
            text-align: center;
            max-width: 600px;
            background-color: transparent;
            .tech-desc-text-title {
                font-size: 24px;
                text-transform: uppercase;
                color: red;
                font-weight: 900;
                font-family: 'C23LfStyle' !important;
                padding-bottom: 15px;
            }
            .tech-desc-text-desc {
                font-size: 24px;
            }
        }
    }
}

.sub-1 {
    > div {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        @media (max-width: 768px) {
            align-items: start;
        }
        div.col-12 {
            @media (max-width: 768px) {
                display: flex;
                justify-content: center;
                flex-direction: row;
            }
        }
    }
    .tech-desc-text-container {
        margin-left: 50px;
        @media (max-width: 768px) {
            margin: 0 10px;
        }
    }
}
.sub-2 {
    > div {
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        @media (max-width: 768px) {
            align-items: start;
        }
        div.col-12 {
            @media (max-width: 768px) {
                display: flex;
                justify-content: center;
                flex-direction: row;
            }
        }
    }
    .tech-desc-text-container {
        margin-right: 50px;
        @media (max-width: 768px) {
            margin: 0 10px;
        }
    }
}
.sub-3 {
    > div {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        @media (max-width: 768px) {
            align-items: start;
        }
        div.col-12 {
            @media (max-width: 768px) {
                display: flex;
                justify-content: center;
                flex-direction: row;
            }
        }
    }
    .tech-desc-text-container {
        margin-left: 50px;
        @media (max-width: 768px) {
            margin: 0 10px;
        }
    }
}
.sub-4 {
    > div {
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        @media (max-width: 768px) {
            align-items: start;
        }
        div.col-12 {
            @media (max-width: 768px) {
                display: flex;
                justify-content: center;
                flex-direction: row;
            }
        }
    }
    .tech-desc-text-container {
        margin-right: 50px;
        @media (max-width: 768px) {
            margin: 0 10px;
        }
    }
}

.sub-5 {
    > div {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        @media (max-width: 768px) {
            align-items: start;
        }
        div.col-12 {
            @media (max-width: 768px) {
                display: flex;
                justify-content: center;
                flex-direction: row;
            }
        }
    }
    .tech-desc-text-container {
        margin-left: 50px;
        @media (max-width: 768px) {
            margin: 0 10px;
        }
    }
}

.tech-related-products {
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
}

.highlighted {
    background: black;
    a {
        color: white !important;
    }
}

.foil-tech-page-2023 {
    .tech-sticky-header {
        padding: 0;
        background: #f8f7f6;
        .sticky-top-bar {
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding-bottom: 15px;
            .top-bar-left {
                padding-left: 15px;
                a {
                    text-decoration: none;
                }
            }
            .top-bar-right {
                padding-right: 15px;
                a {
                    text-decoration: none;
                }
            }
        }
        .header-button-container {
            padding-bottom: 15px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            > p {
                width: 14%;
                text-align: center;
                border-left: 1px solid black;
                border-right: 1px solid black;
                display: flex;
                align-items: center;
            }

            .header-buttons {
                width: 100%;
                display: flex;
                justify-content: center;
                justify-items: center;
                text-decoration: none;
            }
        }
        @media (max-width: 768px) {
            display: none;
        }
    }

    .tech-header-container {
    }

    .tech-top-container {
        > div.row > div.col-12:first-child {
            background-color: black;
        }
        .tech-top-sub-container {
            > div.row > div.col-md-3 > div > a,
            > div.row > div.col-md-3 > div {
                margin: 0;
                padding: 0;
                border: 0;
            }
            .tech-top-sub-container-flight {
            }
            .tech-top-sub-container-altitude {
            }
            .tech-top-sub-container-wheelbase {
            }
            .tech-top-sub-container-decks {
            }
        }
        .tech-top-container-title {
            margin-top: -5px;
        }
        .tech-top-container-table {
            padding: 50px 10px;
        }
    }

    .tech-main-container {
        background: black;

        .tech-sub-container {
            height: 1000px;
            > div {
                height: 1000px;
            }
            .tech-desc-text-container {
                text-align: left;
                max-width: 600px;
                background-color: transparent;
                @media (max-width: 768px) {
                    background-color: rgba(0, 0, 0, 0.6);
                    padding: 20px;
                }
                .tech-desc-text-title {
                    font-size: 30px;
                    text-transform: uppercase;
                    color: #dddd4a;
                    font-weight: 900;
                    font-family: 'IntegralCF-Bold' !important;
                    padding-bottom: 30px;
                }
                .tech-desc-text-title::after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 30%;
                    border-top: 1px solid #dddd4a;
                    margin-top: 15px;
                }
                .tech-desc-text-desc {
                    font-size: 20px;
                    color: white;
                }
            }
        }
    }

    .sub-1 {
        > div {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            @media (max-width: 768px) {
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
            div.col-12 {
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                }
            }
        }
        .tech-desc-text-container {
            margin-left: 50px;
            @media (max-width: 768px) {
                margin: 0 10px;
            }
        }
    }
    .sub-2 {
        > div {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            @media (max-width: 768px) {
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
            div.col-12 {
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                }
            }
        }
        .tech-desc-text-container {
            margin-left: 50px;
            @media (max-width: 768px) {
                margin: 0 10px;
            }
        }
    }
    .sub-3 {
        > div {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            @media (max-width: 768px) {
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
            div.col-12 {
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                }
            }
        }
        .tech-desc-text-container {
            margin-left: 50px;
            @media (max-width: 768px) {
                margin: 0 10px;
            }
        }
    }
    .sub-4 {
        > div {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            @media (max-width: 768px) {
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
            div.col-12 {
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                }
            }
        }
        .tech-desc-text-container {
            margin-left: 50px;
            @media (max-width: 768px) {
                margin: 0 10px;
            }
        }
    }

    .sub-5 {
        > div {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            @media (max-width: 768px) {
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
            div.col-12 {
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                }
            }
        }
        .tech-desc-text-container {
            margin-left: 50px;
            @media (max-width: 768px) {
                margin: 0 10px;
            }
        }
    }
    .sub-6 {
        > div {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            @media (max-width: 768px) {
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
            div.col-12 {
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                }
            }
        }
        .tech-desc-text-container {
            margin-left: 50px;
            @media (max-width: 768px) {
                margin: 0 10px;
            }
        }
    }
    .sub-7 {
        > div {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            @media (max-width: 768px) {
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
            div.col-12 {
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                }
            }
        }
        .tech-desc-text-container {
            margin-left: 50px;
            @media (max-width: 768px) {
                margin: 0 10px;
            }
        }
    }
    .sub-8 {
        > div {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            @media (max-width: 768px) {
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
            div.col-12 {
                @media (max-width: 768px) {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                }
            }
        }
        .tech-desc-text-container {
            margin-left: 50px;
            @media (max-width: 768px) {
                margin: 0 10px;
            }
        }
    }

    .tech-related-products {
    }

    .sticky {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
    }

    .highlighted {
        background: black;
        a {
            color: white !important;
        }
    }
}

@keyframes fade-enter {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-exit {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .sub-animated-enter {
        animation: fade-enter 1s 1;
        opacity: 1;
    }
    .sub-animated-exit {
        animation: fade-exit 1s 1;
        opacity: 0;
    }
}
