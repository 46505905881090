.online-retailer-section {
    display: flex;
    justify-content: center;
    margin: 30px 30px;
}

/* .online-retailer-section {
    display: flex;
    justify-content: center;
    margin: 30px 0;
} */
