.ms-buybox {
    padding-bottom: 30px;
}

.ms-buybox__quantity,
.ms-buybox__add-to-cart-container {
    margin: 10px 0;
}

.ob-summary {
    flex-direction: column;
    padding-bottom: 30px;
}

.msc-add-to-cart:hover,
.msc-add-to-cart:focus {
    background-color: $brandSecondaryColor;
    border-color: $brandSecondaryColor;
}

.msc-add-to-cart {
    background-color: $brandSecondaryColor;
    border: 1px solid $brandSecondaryColor;
    color: #000;
    font-size: 16px;
    text-transform: uppercase;
    font-family: $bodyfonts;
    font-weight: bold;
}

.obrien-buybox > div > ul {
    list-style-type: circle;

    li::marker {
        // color: $brandSecondaryColor;
        color: black;
        font-size: 16px;
    }

    li {
        font-size: 15px;
    }
}

.wakeboard-image {
    margin-top: -150px;
}

@media only screen and (max-width: 600px) {
    .wakeboard-image {
        margin-top: 0px;
    }
}

.msc-ss-carousel-strip {
    display: flex;
    justify-content: center;
}

.ob-summary-container > .ob-summary {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 5px;
    margin: auto auto;
}

.ms-buybox__quantity {
    padding: 0 5px;

    input {
        width: 44px;
        height: 44px;
        text-align: center;
    }
}

.ms-buybox__add-to-cart-container {
    flex-grow: 3;
    padding: 0 5px;

    button {
        width: 100%;
    }
}

.ms-buybox__add-to-wishlist-container {
    padding: 0 5px;

    button {
        width: 44px;
        height: 44px;
    }
}

.ms-buybox__quantity,
.ms-buybox__add-to-cart-container,
.ms-buybox__add-to-wishlist-container {
    display: flex;
    justify-content: center;
}

.ms-buybox__product-quantity-label {
    display: none;
}

@media (min-width: 992px) {
    .ms-media-gallery .ms-media-gallery__thumbnail-item {
        height: 150px;
    }
}

@media (min-width: $msv-breakpoint-m) {
    .ms-media-gallery {
        .msc-carousel__item {
            max-height: 850px;
        }
    }
}

.liquid-wakeboard-spacer {
    display: none;
}

.liquid-wakesurf-spacer {
    height: 75px;
}

.wakeboard-video-container {
    padding: 30px 0;
    .wakeboard-video {
    }
}

.feature-focus {
    padding-bottom: 30px;
}

.feature-focus-section {
    background-color: #dddd4a;
    padding: 0;
    @media only screen and (max-width: 768px) {
        margin: 0 30px;
    }
    .ms-content-block.feature-focus-text.upc-text-block {
        flex-direction: column;
        padding-top: 0px;

        .ms-content-block__image {
            padding-bottom: 50px;
            justify-content: flex-start;
        }
        .ms-content-block__details {
            p {
                font-family: 'Futura PT Bold', Arial, Tahoma, sans-serif !important;
                font-size: 22px;
            }
            h5 {
                font-weight: 900;
                font-size: 32px;
                font-family: 'Futura PT Bold', Arial, Tahoma, sans-serif !important;
            }
        }
    }
}
.feature-focus-section.feature-focus-reversed {
    background-color: #ebebeb;
    .quote-text-block {
        .ms-content-block__details {
            .ms-content-block__text {
                p {
                    text-transform: uppercase;
                    font-family: 'Proto Mono Medium', 'Pitch Bold', Arial, Tahoma, sans-serif;
                }
            }
        }
    }
}

.feature-focus-text {
    padding: 40px;
    @media only screen and (max-width: 768px) {
        padding: 20px 0;
    }
}

.feature-focus-text > div > p,
.feature-focus-text > .ms-content-block__details > div > p {
    // font-family: $pitch, Arial, Tahoma, sans-serif;
    padding-bottom: 20px;
}

.upc-text-block > div > p,
.upc-text-block > .ms-content-block__details > div > p {
    line-height: 26px;
}

.quote-text-block > div > p,
.quote-text-block > .ms-content-block__details > div > p {
    line-height: 40px;
}

.ms-content-block.feature-focus-text {
    display: flex;
    flex-direction: column-reverse;
}

.ms-content-block.feature-focus-text > .ms-content-block__image {
    display: flex;
    justify-content: center;
}

.feature-focus-image {
    height: 100%;
    padding: 0;
}

.feature-focus-image > div.ms-content-block__image {
    display: flex;
    justify-content: center;
    height: 100%;
    overflow: hidden;
}

.feature-focus-image > div > picture {
    flex: none;
}
.feature-focus-image > div > picture > img {
    height: 100%;
}

.feature-focus-reversed > div {
    display: flex;
    flex-direction: row-reverse;
}

.feature-focus-image-section {
    /* padding: 30px 0; */
    padding: 0;
}

.feature-focus > div.row > div.col-12:nth-child(1) {
    background: #dddd4a;
}

.feature-focus > div.row > div.col-12:nth-child(2) {
    background: #ebebeb;
}

.ms-tab-tabs-section {
    justify-content: center;
}

.ob-summary-container > h1.ms-buybox__product-title {
    font-family: 'IntegralCF-Bold' !important;
}

.ms-buybox__product-title {
    @media only screen and (max-width: 768px) {
        font-size: 30px;
        text-align: center;
    }
}

.related-products {
    margin-top: 50px;
    .related-products-module {
        .msc-product__title {
            font-family: 'IntegralCF-Bold', Arial, Helvetica, sans-serif !important;
        }
    }
}

.retail-button {
    background-color: #dddd4a;
    border: none;
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
    a {
        text-transform: uppercase;
        font-weight: bold;
    }
}

.related-products {
    .msc-ss-carousel-strip {
        justify-content: flex-start;
    }
}

.ms-product-collection__item {
    width: 250px;
}

.wakeboard-tabs {
    ul.ms-tab-tabs-section {
        li {
            div {
                font-weight: bold;
            }
        }
    }
}

.ms-wishlist-items__product-status {
    display: none;
}

/* Comp Vest PDP SCSS */

.comp-vest-pdp {
    .comp-vest-size-warning-container {
        padding-top: 20px;
        > div.row {
            display: flex;
            flex-direction: row;
            > div.col-12:nth-of-type(1) {
                width: 66%;
                max-width: 66%;
                @media (max-width: 768px) {
                    width: 100%;
                    max-width: 100%;
                }
            }
            > div.col-12:nth-of-type(2) {
                width: 33%;
                max-width: 33%;
                @media (max-width: 768px) {
                    width: 100%;
                    max-width: 100%;
                }
                display: flex;
                .comp-vest-size-warning-warning {
                    width: 100%;
                    max-width: 100%;
                    .comp-vest-size-warning-accordion {
                        .ms-accordion-header-section {
                            display: none;
                        }
                        .ms-accordion-item__drawer {
                            border-top: 0;
                            .drawer__button {
                                color: white;
                                background-color: red;
                                align-items: center;
                                padding-left: 15px;
                                .drawer__buttontext {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                /*
                .comp-vest-size-warning-warning {
                    filter: grayscale(1);
                    transition: 0.5s;
                }
                .comp-vest-size-warning-warning:hover {
                    filter: grayscale(0);
                    transition: 0.5s;
                } */
            }
            > div.col-12 {
                padding: 0 15px;
                @media (max-width: 768px) {
                    width: 100%;
                    max-width: 100%;
                    padding: 15px 0;
                }
            }
        }
    }
}

// Attatch .linked-image class to an obrien content module image and use an action link (with text) to allow the image to be clickable
.linked-image {
    display: grid;
    grid-template: 1fr / 1fr;
    place-items: center;
    > * {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
    }
    > .obrien-content-block-simple__image {
        //placeholder for image manipulation
    }
    > .obrien-content-block-simple__details {
        height: 100%;
        width: 100%;
        * {
            height: 100%;
            width: 100%;
            color: transparent;
        }
    }
}

//2023 Foil Product Pages
.foil-banner,
.pdp-banner {
    .desktop-image {
        @media (max-width: 768px) {
            display: none;
        }
    }
    .mobile-image {
        display: none;
        @media (max-width: 768px) {
            display: grid;
        }
    }
}

//b2b Product Template Add button
.b2b {
    div.ms-buybox__add-to-order-template-container {
        display: flex;
        justify-content: center;
        padding: 0 5px;
        button.msc-add-to-order-template-icon {
            width: 44px;
            height: 44px;
        }
    }
    div.retail-button {
        display: none;
    }
}
