;
@import 'bootstrap/scss/bootstrap';

// call to the fabrikam design kit
@import '@msdyn365-commerce-modules/fabrikam-design-kit/src/modules/fabrikam/styles/fabrikam.theme.scss';

// primary variables
$brandPrimaryColor: #ffffff;
$brandSecondaryColor: #dddd4a;
$backgroundDefaultColor: #ffffff;
$textDefaultColor: #333333;
$bodyFont: Arial;
$displayFont: Impact;
$borderRadius: 4;
$iconStyle: Line;
$categoryColor: #000;
$expandedCategoryColor: #000;
// $footer-bg-color: #F8F7F6;
$footerbgcolor: #ffffff;

:root {
    --brandPrimaryColor: #{brandPrimaryColor};
    --brandSecondaryColor: #{brandSecondaryColor};
    --backgroundDefaultColor: #{$backgroundDefaultColor};
    --textDefaultColor: #{textDefaultColor};
    --bodyFont: #{bodyFont};
    --displayFont: #{displayFont};
    --borderRadius: #{borderRadius};
    --iconStyle: #{iconStyle};
    --categoryColor: #{categoryColor};
    --expandedCategoryColor: #{expandedCategoryColor};
    --msv-footer-bg: #{footerbgcolor};
    --msv-accent-brand-alt-color: #{brandSecondaryColor};
    --msv-accent-brand-color: #{brandSecondaryColor};
    --msv-search-form-bg: #d3d3d3;
    --msv-search-form-border: #d3d3d3;
    --msv-search-form-search-btn-color: #dddd4a;
    --msv-cookie-compliance-font-size: 14px;
    --msv-search-font-family: 'Roboto Mono', monospace;
}

body {
    background-color: var(--backgroundDefaultColor);
}

// imports of sass sub folders
@import 'settings/index';
@import 'tools/index';
@import 'modules/index';
@import 'pages/index';

//fabrikam overides

p,
.li-in-p {
    line-height: 28px;
    font-size: 15px;
    padding: 5px 0;
}

// Brand Story Image
.brand-story-image > div.row > div.col-12 > div.single-header-2020 > div.inside-xl {
    display: none;
}

main#main {
    background-color: #f3f3f3;
}

.content-divider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 30px;
}

.divider-with-text {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1,
    h2,
    h3,
    h4,
    h5 {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #000;
        line-height: 0.1em !important;
        margin: 20px 0;
    }

    h1 span,
    h2 span,
    h3 span,
    h4 span,
    h5 span {
        background: #f3f3f3;
        padding: 0 10px;
    }
}
