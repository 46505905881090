.email-signup-container {
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
        max-width: 95vh;
        width: 95vh;
    }
    @media (min-width: 768px) {
        max-width: 600px;
        width: 600px;
    }
    #mc_embed_signup_scroll {
        max-width: 100%;
        width: 100%;
        padding-bottom: 30px;
        > .mc-field-group {
            padding: 3px 0;
        }
        .mc-field-group {
            display: flex;
            flex-direction: column;
            label {
                padding-top: 5px;
            }
        }
        .mc-address-group {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            gap: 10%;
            > .mc-field-group {
                width: 45%;
                max-width: 45%;
            }
            > .mc-address-fields-group {
                width: 100%;
                max-width: 100%;
            }
        }
        input.button {
            border: 1px solid black;
            padding: 10px 50px;
            transition: 1s;
        }
        input.button:hover {
            color: white;
            background-color: black;
            transition: 1s;
        }
    }
}
