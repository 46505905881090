.foil-info-container {
    padding: 30px 0;
    .foil-info-foil-container {
        > div.row {
            display: flex;
            flex-direction: row;
            @media (max-width: 768px) {
                flex-direction: column;
            }
            > div.col-12:first-child {
                max-width: 40%;
                height: 300px;
                @media (max-width: 768px) {
                    max-width: 100%;
                    height: 100%;
                }
            }
            > div.col-12:last-child {
                max-width: 60%;
                @media (max-width: 768px) {
                    max-width: 100%;
                    padding: 0 30px;
                    text-align: center;
                }
            }
        }

        .foil-info-foil-image-container {
            height: 300px;
            .foil-info-foil-image {
                height: 300px;
                .ms-content-block__image {
                    display: flex;
                    justify-content: flex-start;
                    @media (max-width: 768px) {
                        justify-content: center;
                    }
                }
                img {
                    height: 300px;
                    width: auto;
                }
            }
        }
        .foil-info-foil-content-container {
            .foil-info-foil-content-title {
            }
            .foil-info-foil-content-text {
            }
        }
    }
    .foil-reverse {
        > div.row {
            flex-direction: row-reverse;
            @media (max-width: 768px) {
                flex-direction: column;
            }
        }
        .foil-info-foil-image-container {
            .foil-info-foil-image {
                .ms-content-block__image {
                    justify-content: flex-end;
                    @media (max-width: 768px) {
                        justify-content: center;
                    }
                }
            }
        }
    }
}
