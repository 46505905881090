.ms-content-block.home-page-sub-slider-boxes {
    padding: 20px 10px;
}

.home-page-sub-slider-boxes {
    h2 {
        text-align: left !important; //remove !important after deployment
        font-size: 18px !important; //remove !important after deployment
        font-family: IntegralCF-Bold, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
        text-transform: uppercase !important; //remove !important after deployment
        margin-bottom: 0px !important;
    }
    p {
        text-align: left !important; //remove !important after deployment
        font-size: 12px !important; //remove !important after deployment
        line-height: 12px !important;
        text-decoration: underline !important; //remove !important after deployment
        color: black !important; //remove !important after deployment
        text-transform: uppercase !important; //remove !important after deployment
    }
}

.home-featured-products {
    height: 350px;
    margin-bottom: 110px;
    .ms-product-collection__heading {
        text-align: center;
        // padding-left: 100px;
    }
    .msc-ss-carousel-strip {
        justify-content: flex-start;
    }
    .ms-product-collection {
        margin: 0 !important;
    }
}

.homepage-main-row > div > div.col-md-3 {
    @media only screen and (max-width: 768px) {
        width: 50%;
    }
    @media only screen and (max-width: 400px) {
        width: 100%;
    }
}

.homepage-foil-series-container {
    background-color: #e6e6e6;
    .homepage-foil-series {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.homepage-wakesurf-edge-container {
    background-color: #e6e6e6;
    .homepage-wakesurf-edge {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.homepage-instagram-container {
    .homepage-instagram {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.home-podcast-section {
    @media screen and (max-width: 768px) {
        background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/jfcsjcbsqg/imageFileData/MAeyNQ?ver=c8c5);
        background-size: contain;
        background-repeat: no-repeat;
    }
    @media screen and (min-width: 768px) {
        background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/jfcsjcbsqg/imageFileData/MAeE0a?ver=f9dc);
        background-size: cover;
    }
    > div.row > div.col-12 {
        display: grid;
        @media screen and (max-width: 768px) {
            grid-template-columns: 100%;
            grid-template-rows: 100%;
        }
        @media screen and (min-width: 768px) {
            grid-template-columns: 50% 50%;
            grid-template-rows: auto;
        }
    }
    .home-podcast {
        grid-column: 1 / span 1;
        grid-row: 1;
        padding: 20px;
        p {
            display: flex;
            justify-content: center;
            align-items: center;
            iframe {
                height: 100%;
                width: 100%;
                min-height: 500px;
                max-height: 600px;

                @media screen and (max-width: 768px) {
                    min-height: 300px;
                    max-width: 450px;
                }
            }
            @media screen and (max-width: 768px) {
                grid-template-columns: 100%;
                grid-template-rows: 100%;
                margin-top: 35%;
                margin-bottom: 50%;
            }
        }
    }
}

.home-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px;
    h3 {
        font-family: IntegralCF-Bold, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    }
    img {
        max-height: 50px;
    }
}

.homepage-main-row {
    padding-top: 0px;
    padding-bottom: 50px;
}

.homepage-instagram-container,
.homepage-foil-container {
    padding-top: 50px;
    padding-bottom: 50px;
}

.homepage-fullwidth-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.homepage-fullwidth-video iframe,
.homepage-fullwidth-video object,
.homepage-fullwidth-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.homepage-instagram {
    h2 {
        font-family: IntegralCF-Bold, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
    }
    margin-bottom: 50px;
}

/*2023 Homepage Redesign */

.homepage-foil-container {
    .homepage-foil {
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: white;
        div.row > div.col-md-6:nth-child(1) {
            .homepage-foil-image-left {
                height: 100%;
                .ms-content-block__details {
                    height: 100%;
                    h2 {
                        font-family: IntegralCF-Bold, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
                    }
                    a {
                        border: 1px solid black;
                        padding: 10px 50px;
                        transition: 1s;
                    }
                    a:hover {
                        color: white;
                        background-color: black;
                        transition: 1s;
                    }
                }
            }
        }
        @media (min-width: 768px) {
            div.row > div.col-md-6:nth-child(1) {
                flex: 0 0 25% !important;
                max-width: 25% !important;
                padding-left: 30px;
                .ms-content-block__text {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                }
            }

            div.row > div.col-md-6:nth-child(2) {
                flex: 0 0 75% !important;
                max-width: 75% !important;
            }
        }
        @media (max-width: 768px) {
            div.row > div.col-md-6:nth-child(1) {
                flex: 0 0 100% !important;
                max-width: 100% !important;
                padding: 0 15px;
                .ms-content-block__text {
                    height: 100%;
                    text-align: center;
                }
            }

            div.row > div.col-md-6:nth-child(2) {
                flex: 0 0 100% !important;
                max-width: 100% !important;
            }
        }
    }
}
.homepage-23-split-container {
    .homepage-23-split {
        padding-top: 30px;
        padding-bottom: 30px;
        div.row > div.col-md-6:nth-child(2) {
            .homepage-23-split-image-right {
                height: 100%;
                .ms-content-block__details {
                    height: 100%;
                    h2 {
                        font-family: IntegralCF-Bold, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
                    }
                    a {
                        border: 1px solid black;
                        padding: 10px 50px;
                        transition: 1s;
                    }
                    a:hover {
                        color: white;
                        background-color: black;
                        transition: 1s;
                    }
                }
            }
        }
        @media (min-width: 768px) {
            div.row > div.col-md-6:nth-child(1) {
                flex: 0 0 66% !important;
                max-width: 66% !important;
            }
            div.row > div.col-md-6:nth-child(2) {
                flex: 0 0 33% !important;
                max-width: 33% !important;
                .homepage-23-split-image-right {
                    height: 100%;
                    padding-left: 30px;
                    .ms-content-block__text {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                    }
                }
            }
        }
        @media (max-width: 768px) {
            div.row > div.col-md-6:nth-child(1) {
                flex: 0 0 100% !important;
                max-width: 100% !important;
            }
            div.row > div.col-md-6:nth-child(2) {
                flex: 0 0 100% !important;
                max-width: 100% !important;
                .homepage-23-split-image-right {
                    height: 100%;
                    padding: 0 15px;
                    .ms-content-block__text {
                        height: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.homepage-video-carousel-section {
    .homepage-video-carousel {
        padding-top: 30px;
        padding-bottom: 30px;
        .homepage-video-carousel-title {
            text-align: center;
            h2 {
                font-family: IntegralCF-Bold, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
                letter-spacing: 3px;
            }
        }

        .homepage-video-carousel-tabs {
            .homepage-video-carousel-tab-section {
            }
        }
    }
}

.homepage-athlete-xp-container {
    .homepage-athlete-xp {
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: white;

        div.row > div.col-md-6:nth-child(1) {
            .homepage-athlete-xp-image-left {
                .ms-content-block__image {
                    display: flex;
                    justify-content: center;
                }
                .ms-content-block__details {
                    text-align: center;
                    h2 {
                        font-family: IntegralCF-Bold, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
                    }
                    a {
                        border: 1px solid black;
                        padding: 10px 50px;
                        transition: 1s;
                    }
                    a:hover {
                        color: white;
                        background-color: black;
                        transition: 1s;
                    }
                }
            }
        }
        div.row > div.col-md-6:nth-child(2) {
            .homepage-athlete-xp-image-right {
                .ms-content-block__details {
                    text-align: center;
                    h2 {
                        font-family: IntegralCF-Bold, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
                    }
                    a {
                        border: 1px solid black;
                        padding: 10px 50px;
                        transition: 1s;
                    }
                    a:hover {
                        color: white;
                        background-color: black;
                        transition: 1s;
                    }
                }
            }
        }
        @media (max-width: 768px) {
            /* .homepage-athlete-xp-header-image {
                width: 300%;
                margin-left: -100%;
                overflow: hidden !important;
                .ms-content-block__details {
                    display:none;
                }
            } */
            div.row > div.col-md-6:nth-child(1) {
                flex: 0 0 100% !important;
                max-width: 100% !important;
            }
            div.row > div.col-md-6:nth-child(2) {
                flex: 0 0 100% !important;
                max-width: 100% !important;
            }
        }
        @media (min-width: 768px) {
            div.row > div.col-md-6:nth-child(1) {
                flex: 0 0 50% !important;
                max-width: 50% !important;
                .homepage-athlete-xp-image-left {
                    padding-left: 30px;
                    .ms-content-block__text {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                    }
                }
            }
            div.row > div.col-md-6:nth-child(2) {
                flex: 0 0 50% !important;
                max-width: 50% !important;
                .homepage-athlete-xp-image-right {
                    padding-left: 30px;
                    .ms-content-block__text {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                    }
                }
            }
        }
    }
}
