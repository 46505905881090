.faq-page {
    .faq-buttons-container {
        div.faq-buttons {
            display: flex;
            justify-content: center !important;
            align-items: center;
            @media (max-width: 768px) {
                flex-direction: column;
            }
            a {
                border: 1px solid #dddd4a;
                background-color: #dddd4a;
                color: #000;
                padding: 10px 50px;
                transition: 1s;
                text-decoration: none;
                margin: 0 25px;
                @media (max-width: 768px) {
                    width: 100%;
                    margin: 10px 0;
                    justify-content: center;
                    display: flex;
                }
            }
            a:hover {
                color: white;
                background-color: black;
                transition: 1s;
            }
        }
    }
    .drawer {
        button > span {
            font-weight: bold;
        }
    }
    .ms-accordion-item-content {
        div.ms-text-block {
            padding-top: 0px;
            margin-top: 5px;
            p {
                font-size: 18px;
            }
        }
    }
}
