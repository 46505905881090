.athlete-experience-title-container {
    padding-bottom: 50px;
    .athlete-title {
        font-weight: bold;
        span.athlete-title-title {
            font-size: 28px;
            padding-bottom: 30px;
        }
    }
}

.athlete-experience-initial-container {
    padding-bottom: 50px;
    > div.row > div.col-md-6 {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
    .athlete-text-1-container {
        .athlete-text-1 {
            text-align: center;
            padding-top: 15px;
            padding-bottom: 15px;

            span.athlete-text-1-title {
                font-size: 24px;
            }

            p {
                font-weight: bold;
                font-size: 21px;
                line-height: 40px;
            }
        }
    }
    .athlete-image-1-container {
        .athlete-image-1 {
            img {
                max-height: 350px;
                width: auto;
            }
        }
    }
}

.athlete-video-container {
    padding-bottom: 50px;
    .athlete-video-title {
        font-family: 'C23LfStyle', 'IntegralCF-Bold', Arial, Tahoma, sans-serif !important;
        font-size: 30px;
    }

    .athlete-video-embed {
        text-align: center;
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.athlete-music-container {
    padding-bottom: 50px;
    .athlete-music-title {
        font-family: 'C23LfStyle', 'IntegralCF-Bold', Arial, Tahoma, sans-serif !important;
        font-size: 30px;
    }

    .athlete-music-embed {
    }
}

.athlete-stretch-container {
    padding-bottom: 50px;
    .athlete-stretch-title {
        font-family: 'C23LfStyle', 'IntegralCF-Bold', Arial, Tahoma, sans-serif !important;
        font-size: 30px;
    }

    .athlete-stretch-body {
        text-align: center;
        h5 {
            padding-top: 20px;
        }
        p {
            font-size: 22px;
        }
    }
}

.athlete-about-container {
    padding-bottom: 50px;
    .athlete-about-title {
        font-family: 'C23LfStyle', 'IntegralCF-Bold', Arial, Tahoma, sans-serif !important;
        font-size: 30px;
        padding-bottom: 15px;
    }

    .athlete-about-content-container {
        .athlete-about-content-1,
        .athlete-about-content-2 {
            padding-left: 15px;
            padding-right: 15px;
            text-align: center;
            h5 {
                padding-top: 15px;
                font-size: 16px;
            }
        }
    }
}
.athlete-social-container {
    .athlete-social-title {
        font-family: 'C23LfStyle', 'IntegralCF-Bold', Arial, Tahoma, sans-serif !important;
        font-size: 30px;
    }

    .athlete-social-embed {
        padding-bottom: 30px;
    }
}
