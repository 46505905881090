.sticker-heading {
    .ms-content-block {
        justify-content: center;
        display: flex;
        align-items: center;
    }
}

.sticker-container {
    padding: 0 0 50px 0;
    .sticker-container-left {
        padding: 0 15px;
        .sticker-text {
        }
    }
    .sticker-container-right {
        padding: 0 15px;
        .sticker-image {
        }
    }
}
