// overrides
.ms-header .ms-header__topbar.liquid {
    height: auto;
    justify-content: space-between;
}

.ms-header .ms-search__form-control:focus,
.ms-header .ms-search__form-cancelSearch:focus {
    outline-offset: 0px;
}

.ms-header .ms-search__icon:focus {
    outline-offset: 0px !important;
    border: none;
}

.ms-header .ms-header__account-info .ms-header__signin-button:focus {
    outline-offset: 0px !important;
    border: none;
}

// I do not know why... I'm confused as well as to why this is display: none; It makes no sense.

.ms-header .ms-header__account-info {
    display: block;
    @media (max-width: 768px) {
        display: none;
    }
}

.ms-cookie-compliance {
    width: 100%;
    bottom: 0;
    z-index: 10000;
    position: fixed;
    background-color: #d4d4d4;
}

.ms-promo-banner {
    background-color: $brandSecondaryColor;
    border-color: $brandSecondaryColor;
}

.ms-search__form-submitSearch {
    background-color: #dddd4a !important;
}

.ms-search__form-submitSearch:hover,
.ms-search__form-submitSearch:active,
.ms-search__form-submitSearch:focus {
    background-color: #242424 !important;
}

.ms-search__form-control {
    background-color: #d3d3d3;
    border: 1px solid #d3d3d3;
}

.ms-search__form-cancelSearch {
    background-color: #d3d3d3;
}

.ms-promo-banner__content {
    text-align: center;
    font-family: $integral, Arial, Tahoma, sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    padding: 2px 8px;
}

.ms-promo-banner .ms-promo-banner__link {
    text-decoration: none;
    font-weight: bold;
}

.ms-promo-banner .ms-promo-banner__text,
.ms-promo-banner .ms-promo-banner__link {
    color: #000;
}

.ms-promo-banner .ms-promo-banner__close-button {
    position: absolute;
    right: 0;
}

/*Mobile Styling */

.liquid-test:nth-child(1) {
    @media (max-width: 992px) {
        display: flex;
        flex-direction: row-reverse;
    }
}

.ms-header .ms-header__nav-icon {
    color: black;
}

.ms-header .ms-header__nav-icon::before {
    @media (max-width: 992px) {
        font-size: 24px;
    }
}

/*@media (min-width: 992px) {
    ul.ms-nav__list.inside-xxl.level-2 > li.ms-nav__list__item > a.ms-nav__list__item__link:hover {
        background-color: #f8f7f6 !important;
    }

    ul.ms-nav__list > li.ms-nav__list__item > a.ms-nav__list__item__link:hover {
        background-color: #e7e7e7 !important;
    }
} */

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:focus,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:focus,
.ms-nav__list__item__button:focus,
.ms-nav__list__item__link:focus,
.ms-header__container .ms-header__logo a:focus,
.ms-search__form-control:focus {
    border: none;
    outline: none;
}

.obrien-header-2020 {
    header.ms-header {
        div.liquid-test {
            img {
                max-width: 250px;
            }
        }
    }
}

.ms-header__account-info {
    span,
    ::before {
        color: black !important;
    }
}

.ms-header__logo {
    @media (max-width: 400px) {
        display: block !important;
        img {
            max-width: 175px;
            width: 1750px;
        }
    }
    @media (max-width: 300px) {
        display: block !important;
        img {
            max-width: 100px;
            width: 100px;
        }
    }
}

.ms-header__signout-button-text {
    padding: 10px 15px;
}
