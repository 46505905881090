.twenty-twenty-two-special-product-page {
    .ms-buybox__product-title {
        font-family: 'IntegralCF-Bold', Arial, Tahoma, sans-serif !important;
        text-transform: uppercase;
    }

    .wakeboard-accordion-container {
        padding-bottom: 30px;
    }
    .feature-focus-2022 {
        padding: 30px 0;
        .ms-text-block {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        .feature-focus-2022-section > div {
            display: flex;
            div.col-md-6:last-of-type {
                display: flex;
                align-items: center;
            }
            .feature-focus-2022-section-image {
                background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MA5xot?pubver=1);
                min-height: 400px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .feature-focus-2022-section-text {
                padding: 40px;
                @media only screen and (max-width: 768px) {
                    padding: 20px;
                }

                h1 {
                    font-size: 24px;
                    font-family: 'IntegralCF-Bold', Arial, Tahoma, sans-serif !important;
                }
            }
        }
        .feature-focus-2022-section-reversed > div {
            display: flex;
            flex-direction: row-reverse;
        }
        //High Pigment Sublimation Topsheet
        .image-trip-1 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbahM?ver=eb70) !important;
        }
        //Diamond Bat-Tail Shape
        .image-trip-2 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbn3X?ver=ed7e) !important;
        }
        //Precision PU Core
        .image-trip-3 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAba8r?ver=4e2a) !important;
        }
        //Removable Center Fins
        .image-trip-4 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbkA1?ver=7ddd) !important;
        }
        //Molded-In Side Fins
        .image-trip-5 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbfuC?ver=4116) !important;
        }

        //Carbon Innegra Lightweight Construction
        .image-sting-1 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbkZO?ver=455b) !important;
        }
        //Concave Wing
        .image-sting-2 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbl0P?ver=a08a) !important;
        }
        //Carbon Honeycomb Fin
        .image-sting-3 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbnd6?ver=b103) !important;
        }
        //EVA Traction Pad
        .image-sting-4 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAfu0h?ver=9dc2) !important;
        }
        //Carbon Innegra Lightweight Construction
        .image-orb-1 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbfln?ver=f7cd) !important;
        }
        //Dished Chine Hull
        .image-orb-2 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbkIP?ver=7fea) !important;
        }
        //CNC EPS Foam Core
        .image-orb-3 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbkZX?ver=e0d4) !important;
        }
        //Corduroy Traction
        .image-orb-4 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbl0x?ver=e6f9) !important;
        }

        //Horizon 155 Front Wing
        .image-horizon-1 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbkzY?ver=4c32) !important;
        }
        //H24 Tail Wing
        .image-horizon-2 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbl0A?ver=51f9) !important;
        }
        //Carbon Mast
        .image-horizon-3 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbndf?ver=9d4f) !important;
        }
        //Shorter 44cm Fuselage
        .image-horizon-4 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbagt?ver=20fe) !important;
        }
        //3 Piece Shim Kit
        .image-horizon-5 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbkzS?ver=e09c) !important;
        }
    }

    .twenty-twenty-two-wakesurfer-parallax-trip-2 {
        background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbahP?ver=e4fd);
        min-height: 500px;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .twenty-twenty-two-wakesurfer-parallax-sting {
        background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbll6?ver=9861);
        min-height: 500px;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .twenty-twenty-two-wakesurfer-parallax-orb {
        background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbfuF?ver=2428);
        min-height: 500px;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .twenty-twenty-two-wakesurfer-parallax-horizon {
        background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbl0G?ver=a5c2);
        min-height: 500px;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
