// Settings index file
@import 'icons';
@import 'grid';
@import 'custom-fonts';
@import 'basic-padding';

.divider-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
}

// .msc-btn {
//     background-color: #DDDD4A;
//     border: 1px solid #DDDD4A;
//     color: white;
//     padding: 12px 20px;
// }

.msc-btn:focus {
    border: none;
    outline: none;
}

.ms-search__autoSuggest {
    img {
        max-height: 45px;
    }
}

.dont-show {
    display: none;
}

.transparent-text {
    color: transparent !important;
}
