.feature-focus-foils {
    padding: 30px 0px;
    .feature-focus-section-foils {
        padding: 0;
        .feature-focus-image-foils {
            padding: 0;
            div.ms-content-block__image {
                display: flex;
                justify-content: center;
                height: 500px;
                overflow: hidden;
                picture {
                    flex: none;
                    height: 100%;
                    img {
                        width: auto;
                    }
                }
            }
        }
        .feature-focus-text-foils {
            padding: 40px;
            ul {
                padding-top: 15px;
                font-size: 22px;
            }
        }
    }
}
