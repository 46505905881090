.msc-cart-lines-group-wraper__bopis-heading {
    display: none !important;
}

.msc-cart-lines-item {
    margin: 0 !important;
    padding-top: 20px;
}
.msc-address-detail__item-newline,
.msc-address-detail__item-address-detail_Phone,
.ms-checkout__guided-card-body,
.ms-checkout-delivery-options__description,
.ms-checkout-guest-profile__input-label,
.ms-checkout-guest-profile__selected-email,
.ms-checkout-billing-address__shipping-address-checkbox-text,
.ms-checkout-billing-address__heading {
    font-family: 'Roboto Mono', monospace;
}

.ms-checkout-guest-profile__input-text {
    border: 1px solid black;
}

.cart-container {
    @media (min-width: 768px) {
        min-height: 500px;
    }
    a.msc-cart__btn-backtoshopping {
        background-color: #dddd4a;
        border-color: #dddd4a;
    }
}
