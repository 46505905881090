.single-header-2020 {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 341px;

    @media only screen and (min-width: 4000px) {
        height: 1550px !important;
    }

    @media only screen and (min-width: 3400px) and (max-width: 4000px) {
        height: 1350px !important;
    }

    @media only screen and (min-width: 2800px) and (max-width: 3399px) {
        height: 1150px !important;
    }

    @media only screen and (min-width: 2220px) and (max-width: 2799px) {
        height: 950px !important;
    }

    @media only screen and (min-width: 1920px) and (max-width: 2219px) {
        height: 750px !important;
    }

    @media only screen and (min-width: 1330px) and (max-width: 1919px) {
        height: 650px !important;
    }

    @media only screen and (min-width: 1025px) and (max-width: 1330px) {
        height: 500px;
    }

    .lf-header-image > picture > img {
        width: auto;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    /*@media only screen and (min-width: 600px) and (max-width: 730px) {
        min-height: 200px;
    }

    @media only screen and (min-width: 730px) and (max-width: 900px) {
        min-height: 250px;
    }

    @media only screen and (min-width: 900px) and (max-width: 1025px) {
        min-height: 300px;
    }

    @media only screen and (min-width: 1025px) {
        min-height: 350px;
    }
    @media only screen and (min-width: 1500px) {
        min-height: 500px;
    } */

    .inside-xl {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    img {
        display: block;
        position: absolute;
        max-width: none;
    }

    .lf-title {
        padding: 80px 20px 20px;
        position: relative;
        z-index: 10;
        display: none; //remove to show category headings

        margin: 0 20px;
        h2 {
            color: #fff;
            text-shadow: 1px 1px 1px #000;
            line-height: 1;
            margin-bottom: 10px;
            display: inline-block;
            font-size: 2em !important;
            position: relative;
            padding-bottom: 10px;
            @media screen and (min-width: $screen-md) {
                font-size: 3.5em !important;
            }
        }
        h2::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 4px;
            background: $brandSecondaryColor;
            width: 80%;
        }
        @media only screen and (min-width: $screen-md) {
            width: auto;
        }
        @media only screen and (min-width: $screen-xl) {
            width: auto;
            top: auto;
            left: auto;
        }
        div {
            max-width: 40%;
            margin-left: 0;
        }
    }
}

.brand-story-image-container {
    .single-header-2020 {
        width: 100%;
        overflow: hidden;
        position: relative;
        height: 341px;

        @media only screen and (min-width: 1500px) {
            height: 830px;
        }

        @media only screen and (min-width: 1025px) and (max-width: 1500px) {
            height: 500px;
        }

        .lf-header-image > picture > img {
            width: auto;
            height: 100%;
        }
    }
    /* .single-header-2020 {
        @media only screen and (max-width: 600px) {
            min-height: 350px;
        }

        @media only screen and (min-width: 600px) and (max-width: 730px) {
            min-height: 500px;
        }

        @media only screen and (min-width: 730px) and (max-width: 900px) {
            min-height: 625px;
        }

        @media only screen and (min-width: 900px) and (max-width: 1025px) {
            min-height: 700px;
        }

        @media only screen and (min-width: 1025px) {
            min-height: 875px;
        }
        @media only screen and (min-width: 1500px) {
            min-height: 1250px;
        }
    } */
}
