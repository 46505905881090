// overrides

// desktop navigation
.desktop-nav .ms-nav {
    display: none;
    @media screen and (min-width: $msv-breakpoint-l) {
        display: block;
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item {
    margin-top: 0;
}

.ms-header__mobile-hamburger-menu-links {
    .ms-nav.desktop-vp {
        display: block !important;
        @media screen and (min-width: $msv-breakpoint-l) {
            display: none !important;
        }
    }

    .ms-nav__list__item__button,
    .ms-nav__list__item__link {
        padding: 5px 20px;
        background-color: #ccc;
        border-bottom: 2px solid #fff;
        height: auto;
        display: block;
    }
}

/* .ms-nav__list__item__button,
.ms-nav__list__item__link {
  font-family: "IntegralCF-Bold", Arial, Tahoma, sans-serif !important;
} */

.ms-nav > .ms-nav__list .ms-nav__list {
    height: auto;
}

.ms-header__container .ms-header__collapsible-hamburger .ms-nav .ms-nav__list__item__link {
    padding: 5px 20px;
}
