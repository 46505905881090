.register-container {
    padding: 30px 0 150px 0;
    h3 {
        padding-bottom: 30px;
    }
}

.register-button {
    padding-top: 50px;
    .ms-content-block__cta {
        a {
            background-color: #dddd4a;
            border: none;
            color: white;
            padding: 15px 32px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            width: 300px;
            cursor: pointer;
        }
    }
}
