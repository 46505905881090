.international-title-container {
    .international-title-container-text-container {
        .international-title-container-text-container-text {
            padding: 0 20px;
        }
    }
}

.international-accordion {
    margin-bottom: 50px;
    .international-accordion-item {
        .international-accordion-item-text {
            text-align: center;
            padding-top: 0;
        }
        .drawer.ms-accordion-item__drawer > button {
            justify-content: center;
            border: none;
            font-family: $integral, Arial, Tahoma, sans-serif;
        }
    }
}

.ms-accordion-item__drawer .drawer__button[aria-expanded='true']::after {
    margin-left: 10px;
}

.ms-accordion-item__drawer .drawer__button[aria-expanded='false']::after {
    margin-left: 10px;
}

.germany-details {
    display: flex;
    justify-content: center;
    text-align: center;
}
