div[data-exp-event-id='Homepage.PageView'] {
    .ms-nav {
        background: none;
        > .ms-nav {
            &__list {
                background: none;
                &__item {
                    background: transparent;
                }
            }
        }
    }
    .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button,
    .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
        @media screen and (min-width: $screen-md) {
            background-color: transparent;
        }
    }
    .ms-nav > .ms-nav__list > .ms-nav__list__item {
        background: transparent;
    }
}

.ms-nav {
    border: none;
    .ms-nav {
        &__list {
            width: auto;
        }
    }
    &__list {
        &__item {
            height: auto;
            padding: 0 10px;
            @media (min-width: $screen-md) {
                padding: 0;
                margin: 0;
            }
            &__link,
            &__button {
                font-family: $integral !important;
                text-transform: uppercase !important;
                font-size: 1.2em !important;
            }
        }
    }

    > .ms-nav__list {
        > .ms-nav__list__item {
            padding: 0;
            margin: 0;
            @media (min-width: $msv-breakpoint-l) {
                padding: 0;
                margin: 0;
            }
        }
    }
}

.ms-nav__list__item__button,
.ms-nav__list__item__link {
    height: auto;
}

.ms-nav.mobile-vp {
    display: none;
    @media (min-width: $screen-md) {
        display: block;
    }
}

#header-nav-mobile {
    margin: 0;
    height: 100%;
    background: #fff;
    .msc-modal {
        &__content {
            border: none;
            padding: 16px 2px;

            .ms-nav.mobile-vp {
                display: block;
            }
        }
        &__header {
            margin-bottom: 20px;
            .msc-modal {
                &__close-button {
                    right: 12px;
                    color: transparent;
                    text-shadow: none;
                    font-size: 22px;
                }
            }
        }
    }
}

#header-nav-mobile {
    background-color: #f8f7f6;
    .ms-nav.child {
        position: static;
    }
    .msc-modal {
        &__content {
            background: none;
        }
    }
    .ms-nav {
        background: none;
        padding-top: 20px;
        .ms-nav {
            &__list {
                background: none;

                &__item {
                    background: none;
                    padding: 0;
                    &__link,
                    &__button {
                        background: #808080;
                        font-family: $futura;
                        text-transform: uppercase;
                        margin-bottom: 2px;
                        padding: 0 8px;
                        color: #000000;
                    }
                }
            }
        }
    }
    .ms-nav {
        &__list {
            &__mobile {
                &__container {
                    padding: 8px 0;
                    border-bottom: 2px solid #f8f7f6;
                    &__button {
                        background-color: #f8f7f6;
                        color: #000000;
                        padding: 5px 8px;
                    }
                    &__span {
                        display: inline-block;
                        font-family: $futura;
                        text-transform: uppercase;
                        color: #000000;
                        padding-top: 4px;
                    }
                }
            }
        }
        > .ms-nav__list {
            > .ms-nav__list__item {
                > .ms-nav__list {
                    margin-top: 8px;
                }
            }
        }
    }
}

// Mobile menu to work

.ms-header {
    .ms-header {
        &__mobile-hamburger-menu-links {
            .ms-nav.desktop-vp {
                display: block;
            }
        }
        &__collapsible-hamburger {
            background: #f3f3f3;
            .ms-nav.ms-nav.mobile-vp {
                display: block;
                @media (min-width: $screen-md) {
                    display: none;
                }
            }
        }
    }
}

.ms-nav {
    .ms-nav {
        &__list {
            display: block;
            @media (min-width: $msv-breakpoint-l) {
                display: flex;
            }
        }
    }
}

// fabrikam overrides
.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button,
.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
    display: block;
    background-color: #f8f7f6;
    padding: 2px 16px !important;

    color: #000000;
    border: 1px solid #f8f7f6;
}

// mobile sub menu auto height

.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list .ms-nav__list {
    height: auto;
    position: static;
    @media (min-width: $msv-breakpoint-l) {
        position: absolute;
    }
}

// fix mobile menu anchor links

.ms-header__container .ms-header__collapsible-hamburger .ms-nav .ms-nav__list__item__link {
    line-height: inherit;
    padding: 0;
}

// fix box shadow and border on mobile

.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list {
    border: none;
    box-shadow: none;
}

// take out stupid animated hover state

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:hover::before,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:focus::before,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:hover::before,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:focus::before {
    display: none;
}

// this developer was a god damn idiot... Getting rid of transparent hover

.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:hover,
.ms-header__collapsible-hamburger
    .ms-header__collapsible-hamburger
    .ms-nav
    > .ms-nav__list
    > .ms-nav__list__item
    > .ms-nav__list__item__button:focus,
.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:hover,
.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:focus {
    background: #808080;
}

// i want to throat punch this dude.

.ms-header__collapsible-hamburger .ms-nav__list__item__button,
.ms-header__collapsible-hamburger .ms-nav__list__item__link {
    background-color: #f8f7f6;
}

.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list {
    margin-top: 0;
}

.ms-header__collapsible-hamburger {
    height: auto !important;
    background: #f8f7f6;
    .ms-nav__list__item {
        .ms-nav__list__item {
            background: #f8f7f6;
            padding-right: 0;
            padding-left: 15px;
            .ms-nav__list__item__link {
                color: #fff;
                text-decoration: none;
                padding-left: 10px;
                border-bottom: 2px solid #f8f7f6;
            }
        }
    }
}

// Drop down position for desktop navigation

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button::after {
    margin-left: 5px;
}

// sub menu arrows

.ms-nav__list__item__button::after {
    transition: transform 0.4s ease;
    content: $msv-ChevronRight !important;
}

.ms-nav__list__item__button[aria-expanded='true']::after {
    transform: rotate(90deg);
}

.ms-nav__list__item__button::after {
    @media (min-width: $msv-breakpoint-l) {
        content: $msv-ChevronRight;
    }
}

//override the desktop navigation dropdowns
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list {
    @media (min-width: $msv-breakpoint-l) {
        display: block;
        margin-top: 0;
        background: #808080;
        border: none;
        flex-wrap: wrap;
        min-width: 200px;
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list .ms-nav__list__item {
    border: 1px solid #f3f3f3;
    flex: 0 0 50%;
}

/* UNCOMMENT IF USING THE AUTO-GENERATED MENU

.ms-nav.parent > .ms-nav__list > .ms-nav__list__item:nth-child(1) > .ms-nav__list .ms-nav__list__item:nth-child(1) {
    display: none;
}

.ms-nav.parent > .ms-nav__list > .ms-nav__list__item:nth-child(2) > .ms-nav__list .ms-nav__list__item:nth-child(1) {
    display: none;
} */

.ms-nav > .ms-nav__list > .ms-nav__list__item .ms-nav__list__item__link {
    @media (min-width: $msv-breakpoint-l) {
        background-color: #f8f7f6;
        color: #000000;
        padding: 0;
        padding-left: 0px;
        padding-right: 20px;
        text-decoration: none;
        border: 1px solid transparent;
        transition: background-color 0.3s ease;
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item .ms-nav__list__item__link:hover {
    @media (min-width: $msv-breakpoint-l) {
        background-color: #000000;
    }
}

.ms-nav > .ms-nav__list {
    @media (min-width: 992px) {
        position: static;
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item:nth-child(1) > .ms-nav__list,
.ms-nav > .ms-nav__list > .ms-nav__list__item:nth-child(2) > .ms-nav__list {
    @media (min-width: 992px) {
        width: 90%;
        left: 5vw;
        top: 100px;
        display: flex;
        justify-content: space-evenly;
        padding-bottom: 20px;
        background: #f8f7f6;
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item .ms-nav__list__item__link:hover,
.ms-nav > .ms-nav__list > .ms-nav__list__item .ms-nav__list__item__link:hover,
.ms-nav > .ms-nav__list > .ms-nav__list__item .ms-nav__list__item__button:hover {
    @media (min-width: 992px) {
        background-color: #ececec;
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item:nth-child(7) > .ms-nav__list {
    right: 0;
}

//make navigation align center vertically
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
    align-items: center;
    height: 100%;
    padding: 0 10px;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
    background-color: white;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:hover,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:focus,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:hover,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:focus {
    background-color: transparent;
}

.ms-nav.parent > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
    color: black;
}

.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:hover,
.ms-header__collapsible-hamburger
    .ms-header__collapsible-hamburger
    .ms-nav
    > .ms-nav__list
    > .ms-nav__list__item
    > .ms-nav__list__item__button:focus,
.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:hover,
.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:focus,
.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
    color: #fff;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
    background-color: transparent;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list .ms-nav__list__item {
    border: none !important;
    @media only screen and (min-width: 1200px) {
        flex: 0 0 10% !important;
    }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
        flex: 0 0 20% !important;
    }
}

.ms-nav__list.level-2 .ms-nav__list__item .ms-nav__list__item__link {
    margin-top: 10px;
}

.ms-nav__list.level-2 .ms-nav__list__item ul.ms-nav__list {
    display: block;
}

.ms-nav__list.level-2 .ms-nav__list__item ul.ms-nav__list .ms-nav__list__item__link {
    font-family: $futura, sans-serif !important;
    text-transform: none !important;
    font-size: 1em !important;
    line-height: 30px;
    margin-top: 0;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button::after {
    margin-top: 5px;
}

/* Mobile Menu */

.ms-header__collapsible-hamburger .ms-nav__list__item .ms-nav__list__item .ms-nav__list__item__link {
    color: #000000;
}

.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:hover,
.ms-header__collapsible-hamburger
    .ms-header__collapsible-hamburger
    .ms-nav
    > .ms-nav__list
    > .ms-nav__list__item
    > .ms-nav__list__item__button:focus,
.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:hover,
.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:focus,
.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
    color: #000000;
}

.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:hover,
.ms-header__collapsible-hamburger
    .ms-header__collapsible-hamburger
    .ms-nav
    > .ms-nav__list
    > .ms-nav__list__item
    > .ms-nav__list__item__button:focus,
.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:hover,
.ms-header__collapsible-hamburger .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:focus {
    background: #f8f7f6;
}

.b2b {
    header.ms-header {
        li.ms-nav__list__item {
            font-size: 12px;
        }
    }
    .ms-nav > .ms-nav__list > .ms-nav__list__item:nth-child(1) > .ms-nav__list,
    .ms-nav > .ms-nav__list > .ms-nav__list__item:nth-child(2) > .ms-nav__list {
        @media (min-width: 992px) {
            width: auto;
            left: auto;
            top: auto;
            background: #f8f7f6;
            display: block;
            margin-top: 0;
            border: none;
            flex-wrap: wrap;
            min-width: 200px;
            padding-bottom: 0px;
        }
    }

    .ms-nav > .ms-nav__list > .ms-nav__list__item .ms-nav__list__item__link,
    .ms-nav > .ms-nav__list > .ms-nav__list__item .ms-nav__list__item__link,
    .ms-nav > .ms-nav__list > .ms-nav__list__item .ms-nav__list__item__button {
        @media (min-width: $msv-breakpoint-l) {
            background-color: #f8f7f6;
            color: #000000;
            padding: 0;
            padding-left: 20px;
            padding-right: 20px;
            text-decoration: none;
            border: 1px solid transparent;
            transition: background-color 0.3s ease;
            font-size: 12px !important;
        }
    }
    @media (min-width: 992px) {
        .ms-nav .ms-nav__list {
            display: flex;
            justify-content: center;
        }
    }
    .ms-nav > .ms-nav__list > .ms-nav__list__item:nth-child(7) > .ms-nav__list {
        right: inherit !important;
    }
    .ms-nav__list__item__button::after {
        display: none !important;
    }
}
