.solar-fun-factory {
    .top {
        background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAfrl0?ver=e9ea);
        height: 572px;
    }
    .middle {
        background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbkoN?ver=1b64);
        height: 683px;
        color: white !important;
        --msv-text-block-font-color: #ffffff;

        .middle-text {
            padding: 0 3vw;
            max-width: 1000px;
            margin: 0 auto;
        }

        @media only screen and (max-width: 780px) {
            .middle-text {
                padding: 0 5vw;
                p {
                    font-size: 12px !important;
                }
            }
            .middle-text > .row {
                display: grid;
                grid-template-columns: auto !important;
                grid-template-rows: auto auto auto !important;

                > .col-12:nth-child(1) {
                    grid-column: 1 / span 1 !important;
                    grid-row: 1 / span 1 !important;
                }
                > .col-12:nth-child(2) {
                    grid-column: 1 / span 1 !important;
                    grid-row: 2 / span 1 !important;
                }
                > .col-12:nth-child(3) {
                    grid-column: 1 / span 1 !important;
                    grid-row: 3 / span 1 !important;
                }
            }
        }
        .middle-text > .row {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-rows: auto auto;
            column-gap: 30px;

            > .col-12:nth-child(1) {
                grid-column: 1 / span 2;
                grid-row: 1 / span 1;
            }
            > .col-12:nth-child(2) {
                grid-column: 1 / span 1;
                grid-row: 2 / span 1;
            }
            > .col-12:nth-child(3) {
                grid-column: 2 / span 1;
                grid-row: 2 / span 1;
            }
            .middle-title {
                text-align: center;
            }
        }
    }
    .bottom {
        background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbmZN?ver=ffdb);
        height: 1220px;
        .bottom-text {
            padding: 0 3vw;
            padding-top: 0100px;
            max-width: 1000px;
            margin: 0 auto;
        }
        @media only screen and (max-width: 780px) {
            .bottom-text {
                padding: 0 5vw;
                padding-top: 0110px;
                p {
                    font-size: 12px !important;
                }
            }
        }

        .bottom-text > .row {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-rows: auto auto;

            > .col-12:nth-child(1) {
                grid-column: 1 / span 2;
                grid-row: 1 / span 1;
            }
            > .col-12:nth-child(2) {
                grid-column: 1 / span 1;
                grid-row: 2 / span 1;
            }
            > .col-12:nth-child(3) {
                grid-column: 2 / span 1;
                grid-row: 2 / span 1;
            }
            .bottom-title {
                text-align: center;
            }
        }
    }

    @media only screen and (max-width: 1567px) {
        .top {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        .middle {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        .bottom {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    @media only screen and (min-width: 1567px) {
        .top {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        .middle {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        .bottom {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}

.sun-video-container {
    padding: 30px 0;
}
