.privacy-policy
  > div.row
  > div.col-12
  > div
  > div
  > div.container
  > div.row
  > div.col-12
  > div {
  padding: 10px 0;
}
.privacy-policy
  > div.row
  > div.col-12
  > div
  > div
  > div.container
  > div.row
  > div.col-12
  > div
  > ul,
.privacy-policy
  > div.row
  > div.col-12
  > div
  > div
  > div.container
  > div.row
  > div.col-12
  > div
  > ol {
  font-size: 15px;
}
