// this is just a grid that i have set up for displaying pages. You can choose your own grid.

// Breakpoints
$screen-xxxl: 1800px;
$screen-xxl: 1660px;
$screen-xl: 1440px;
$screen-lg: 1200px;
$screen-md: 992px;
$screen-sm: 768px;
$screen-xs: 600px;
$screen-xxs: 480px;
$screen-xxxs: 360px;

$msv-breakpoint-xs: 0px;
$msv-breakpoint-s: 0px;
$msv-breakpoint-m: 768px;
$msv-breakpoint-l: 992px;
$msv-breakpoint-xl: 1440px;

$msv-gutter-width-s: 16px;
$msv-gutter-width-m: 20px;
$msv-gutter-width-l: 60px;

$msv-container-max-width-s: 768px;
$msv-container-max-width-m: 992px;
$msv-container-max-width-l: 1440px;

.always-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.justify {
    justify-content: space-between;
}

.flex-xxs {
    display: block;
    @media screen and (min-width: $screen-xxs) {
        display: flex;
        flex-wrap: wrap;
    }
}

.flex-xs {
    display: block;
    @media screen and (min-width: $screen-xs) {
        display: flex;
        flex-wrap: wrap;
    }
}

.flex-sm {
    display: block;
    @media screen and (min-width: $screen-sm) {
        display: flex;
        flex-wrap: wrap;
    }
}

.flex-md {
    display: block;
    @media screen and (min-width: $screen-md) {
        display: flex;
    }
}

.flex-lg {
    display: block;
    @media screen and (min-width: $screen-lg) {
        display: flex;
    }
}

.flex-xl {
    display: block;
    @media screen and (min-width: $screen-xl) {
        display: flex;
    }
}

.container {
    max-width: 1220px !important;
    margin: 0 auto;
}

@media (min-width: 1440px) {
    .ms-search-result-container .ms-product-search-result__item .msc-product .msc_image {
        width: 334px;
        height: auto;
    }
}

.ms-product-search-result__item .msc-product .msc_image {
    width: 312px;
    height: auto;
}

.msc-product .msc_image {
    width: 290px;
    height: auto;
}
