.b2b {
    .single-header-2020 {
        height: 500px !important;
    }
    .single-header-2020 .lf-header-image > picture > img {
        width: 100%;
        height: auto;
        @media (max-width: 1500px) {
            height: 100%;
            width: auto;
        }
    }
    .b2b-homepage-login-container {
        display: flex;
        justify-content: center;
        .b2b-homepage-login {
            margin-top: -70px;
            margin-bottom: 200px;
            background-color: rgb(250, 250, 250);
            border: dashed 1px rgb(185, 185, 185);
            width: 600px;
            height: 240px;
            .b2b-homepage-login-text {
                display: flex;
                justify-content: center;
                .b2b-homepage-login-link-container {
                    text-align: center;
                    margin-top: 15px;
                    a {
                        padding: 10px 30px;
                        background-color: #dddd4a;
                        color: white;
                        text-decoration: none;
                        font-family: 'Futura PT Bold', Arial, Tahoma, sans-serif;
                    }
                }
            }
        }
    }
    .ms-quick-order {
        .ms-quick-order__description {
            padding-right: 0px;
        }
    }
}
