.wakesurf-edge-container {
    padding: 30px 0;

    .wakesurf-edge-menu {
        padding: 20px 0;
        .wakesurf-edge-menu-item > div > h3 > a {
            text-decoration: none;
        }
        .wakesurf-edge-menu-item > div > h3 > a:hover {
            text-decoration: underline;
        }
        .wakesurf-edge-button {
            div > h3 {
                a {
                    font-size: 16px;
                    text-decoration: none;
                    padding: 15px 30px;
                    background: #f3f3f3;
                }
            }
        }
    }

    .wakesurf-edge-divider-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .wakesurf-edge-divider {
        }
    }
    .wakesurf-edge-video-container {
        .wakesurf-edge-video {
        }
    }
    .wakesurf-edge-section1 {
        .wakesurf-edge-section1-title {
            padding: 20px 0;
        }
        .wakesurf-edge-section1-subsection {
            @media only screen and (max-width: 730px) {
                padding: 20px 0;
            }
            .wakesurf-edge-section1-image {
            }
            .wakesurf-edge-section1-text {
                padding: 0 10px;
                @media only screen and (max-width: 730px) {
                    text-align: center;
                }
            }
        }
    }
    .wakesurf-edge-section2 {
        .wakesurf-edge-section2-title {
            padding: 20px 0;
        }
        .wakesurf-edge-section2-subsection {
            .wakesurf-edge-section2-image {
            }
            .wakesurf-edge-section2-text {
                padding: 0 10px;
                @media only screen and (max-width: 730px) {
                    text-align: center;
                }
            }
        }
    }
    .wakesurf-edge-section3 {
        .wakesurf-edge-section3-title {
            padding: 20px 0;
        }
        .wakesurf-edge-section3-subsection {
            .wakesurf-edge-section3-testimonial {
                padding: 20px 10px;
                @media only screen and (max-width: 730px) {
                    text-align: center;
                }
            }
        }
    }
    .wakesurf-edge-section4 {
        .wakesurf-edge-section4-title {
        }
        .wakesurf-edge-section4-image {
            padding: 30px 0;
        }
    }
    .wakesurf-edge-section5 {
        .wakesurf-edge-section5-subsection {
            .wakesurf-edge-section5-text {
            }
            .wakesurf-edge-section5-image {
            }
        }
    }
}

.wakesurf-iframe > iframe {
    border: 0;
    @media only screen and (max-width: 540px) {
        height: 1200px;
    }
    @media only screen and (min-width: 540px) {
        height: 700px;
    }
}
