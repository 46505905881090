.footer-list {
    list-style: none;
    font-size: 15px;
}

.footer-list > li > a {
    text-decoration: none;
}

.ms-footer__item.footer-logo {
    padding-top: 50px;
    max-width: 230px;
}

footer .ms-text-block,
footer p,
footer h5,
footer ul,
footer li,
footer a {
    color: white !important;
}

footer {
    background-color: black;
    background-image: url('../../../liquid/img/footer-bg.jpg');
}

.footer-socials {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    img {
      width: 40px;
    }
  }