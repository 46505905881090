.ms-cookie-compliance__container {
    background: #d4d4d4;
}

.ms-cookie-compliance__text {
    p {
        font-size: var(--msv-cookie-compliance-font-size);
    }
}

.ms-cookie-compliance__accept-button {
    background-color: #dddd4a;
    border-color: #dddd4a;
    color: black;
}

.ms-cookie-compliance__accept-button:hover,
.ms-cookie-compliance__accept-button:focus {
    background-color: #000;
    border-color: #000;
    color: white;
}
