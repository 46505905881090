.the-drop-main-container {
    background: #000000;
    padding: 50px 0;
    .the-drop-header {
        padding-bottom: 20px;
        > div.row > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .the-drop-header-image {
            display: flex;
            align-items: center;
        }
        .the-drop-header-text {
            color: white;
            font-family: $integral, Arial, Tahoma, sans-serif;
            letter-spacing: 4px;
            text-align: center;
        }
    }

    .the-drop-container {
        .the-drop-iframe {
            padding: 75.63% 0 0 0;
            position: relative;
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;
            }
        }

        .podcast-iframe {
            width: 100%;
            overflow: hidden;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            background-color: transparent;
            background-position: initial initial;
            background-repeat: initial initial;
        }
    }
}

.podcast-header-image > .ms-content-block__details > div > h2 {
    color: #dddd4a;
}

.podcast-header-text {
}
