.locally-container {
    background-color: #f8f7f6;
    background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/jfcsjcbsqg/imageFileData/MAf3VY?ver=5122');
    .locally-wrapper {
        .locally-module {
            .retailers > h2 {
                text-align: center;
                font-size: 44px;
                padding-bottom: 30px;
            }
            .upc-contain {
                padding-bottom: 30px;
                display: flex;
                flex-direction: row;
                @media only screen and (max-width: 768px) {
                    flex-direction: column;
                }
                .fifty {
                    width: 50%;
                    @media only screen and (max-width: 768px) {
                        width: 100%;
                    }
                    #lcly-button-0 {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        a.lcly-primary-trigger {
                            width: auto;
                            span {
                                padding: 0 30px;
                            }
                        }
                        .lcly-location-switcher-outer {
                            width: 50%;
                        }
                    }
                    .upc-button {
                        background-color: black;
                        border: none;
                        padding: 15px 32px;
                        text-align: center;
                        text-decoration: none;
                        display: inline-block;
                        font-size: 23px;
                        cursor: pointer;
                        margin-top: 20px;
                        a {
                            color: white;
                        }
                    }
                }
            }
        }
    }
}

.us-retailers {
    .us-retailers-header {
        padding-bottom: 30px;
    }
    .us-retailers-iframe {
        padding-bottom: 50px;
    }
}

.retailers .storelocator-store p,
.retailers .storelocator-store a {
    margin: 0 0 0.5em;
    padding: 0;
    color: #58595b !important;
    font-size: 1em;
    white-space: normal;
}
.retailers .upc-contain .upc-button a:link,
.retailers .upc-contain .upc-button a:active,
.retailers .upc-contain .upc-button a:visited {
    background: #dddd4a;
}
.retailers .upc-contain .upc-button a:hover {
    background: #aaaa38;
    text-decoration: none;
}

.locator-iframe {
    padding-bottom: 50px;
}
@media (max-width: 768px) {
    .locator-iframe iframe {
        height: 650px;
    }
}
@media (min-width: 768px) {
    .locator-iframe iframe {
        height: 550px;
    }
}
#storelocator-search-bar {
    background-color: #fff !important;
}

.locally-wrapper {
    display: none;
}

.retailers .forty {
    box-sizing: border-box;
    flex: 0 0 40%;
    background: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAfgVN?ver=baf3) center center
        no-repeat;
    background-size: cover;
}

.storelocator-storename,
.storelocator-address,
.storelocator-phone,
.storelocator-website,
.storelocator-location,
.storelocator-directions-map-span {
    color: black !important;
}
.storelocator-storename a,
.storelocator-address a,
.storelocator-phone a,
.storelocator-website a,
.storelocator-location a,
.storelocator-directions-map-span a {
    color: black !important;
}
