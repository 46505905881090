.wakefoamie-cat-section-video > div > div > .obrien-vimeo {
    height: 400px;
    padding: 20px;
}

.wakefoamie-cat-section > div.row > div {
    padding: 20px;
    display: flex;
    align-items: center;
}

.wakefoamie-cat-container {
    padding: 30px 0;
    background-color: #b9e1da;
}

/* .wakefoamie-cat-container > div.row > div.col-12:nth-child(even) {
    background: #ebebeb;
} */
