.content-start {
    padding-top: 30px;
}

.content-final {
    padding-bottom: 30px;
}

.center-text {
    text-align: center;
}

.ms-text-block {
    margin-top: 0;
    padding-top: 25px;
}

.dont-show {
    display: none !important;
}
