.wake-foamie-info-container-container {
    background-color: #b9e1da;
    .ms-text-block {
        color: #fa5bc3 !important;
    }
    .wake-foamie-info-container {
        padding: 30px 0;
        .wake-foamie-info-wake-foamie-container {
            > div.row {
                display: flex;
                flex-direction: row;
                @media (max-width: 768px) {
                    flex-direction: column;
                }
                > div.col-12:first-child {
                    max-width: 40%;
                    height: 300px;
                    @media (max-width: 768px) {
                        max-width: 100%;
                        height: 100%;
                    }
                }
                > div.col-12:last-child {
                    max-width: 60%;
                    @media (max-width: 768px) {
                        max-width: 100%;
                        padding: 0 30px;
                        text-align: center;
                    }
                }
            }

            .wake-foamie-info-wake-foamie-image-container {
                height: 300px;
                .wake-foamie-info-wake-foamie-image {
                    height: 300px;
                    .ms-content-block__image {
                        display: flex;
                        justify-content: flex-start;
                        @media (max-width: 768px) {
                            justify-content: center;
                        }
                    }
                    img {
                        height: 300px;
                        width: auto;
                    }
                }
            }
            .wake-foamie-info-wake-foamie-content-container {
                .wake-foamie-info-wake-foamie-content-title {
                }
                .wake-foamie-info-wake-foamie-content-text {
                }
            }
        }
        .wake-foamie-reverse {
            > div.row {
                flex-direction: row-reverse;
                @media (max-width: 768px) {
                    flex-direction: column;
                }
            }
            .wake-foamie-info-wake-foamie-image-container {
                .wake-foamie-info-wake-foamie-image {
                    .ms-content-block__image {
                        justify-content: flex-end;
                        @media (max-width: 768px) {
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}
